@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300";
.coin {
  top: var(--coin-from-x, 24px);
  left: var(--coin-from-y, 24px);
  z-index: 100;
  opacity: 0;
  position: absolute;
}

.coin:after {
  content: "$";
  color: #edc46b;
  opacity: 0;
  background: #e3a217;
  border: 2px solid #edc46b;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  font-size: 10px;
  display: flex;
}

.coin--animated, .coin--animated:after {
  animation-delay: var(--coin-delay, 0s);
  animation-duration: var(--coin-duration, 1.5s);
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-fill-mode: both;
}

.coin--animated {
  animation-name: coin-x-axis;
  animation-timing-function: ease-in;
}

.coin--animated:after {
  animation-name: coin-y-axis-and-flip;
  animation-timing-function: ease-out;
}

@keyframes coin-x-axis {
  30% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  to {
    left: calc(var(--coin-to-x) * 1.5);
  }
}

@keyframes coin-y-axis-and-flip {
  30% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  to {
    transform: translateY(calc(var(--coin-to-y) * 1.5)) rotate3d(1, 1, 1, 1080deg);
  }
}

.kxZqba_text {
  line-height: 1;
}

.mnSnnG_hint {
  color: #969696;
  text-align: center;
  width: 100%;
  margin: 10px 0 25px;
  font-size: 16px;
  font-weight: 300;
}

.Cr8UFq_circlesContainer {
  justify-content: center;
  width: 100%;
  height: 180px;
  display: flex;
}

.Cr8UFq_circles {
  flex-direction: column;
  width: 100%;
  display: flex;
}

@media only screen and (width <= 400px) {
  .Cr8UFq_circlesContainer {
    box-sizing: border-box;
    padding: 0 10px;
  }
}

.KU4pnW_input {
  box-sizing: border-box;
  caret-color: #121212;
  color: #121212;
  background: #2b2b2b0f;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 36px;
  margin-right: 5px;
  padding: 10px 0 10px 15px;
  font-size: 16px;
  font-weight: 400;
}

.KU4pnW_text {
  color: #bebebe;
  font-size: 18px;
  font-weight: 700;
}

.YNj7fG_button {
  color: #2b2b2b;
  cursor: pointer;
  background: #bebebe;
  border: none;
  border-radius: 4px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.YNj7fG_loader span:first-child {
  display: flex;
}

.TFw-jG_button {
  width: 103px;
  height: 33px;
  font-size: 18px;
  font-weight: 500;
}

.TFw-jG_button:disabled {
  cursor: default;
  opacity: .5;
}

.TFw-jG_primary {
  color: #2b2b2b;
  background: #bebebe;
}

.TFw-jG_secondary {
  color: #3b3b3b;
  background: #2b2b2b0f;
  border: none;
  border-radius: 4px;
  margin-top: 9px;
  font-size: 15px;
  transition: box-shadow .1s;
}

.TFw-jG_button:not(:disabled):hover {
  background-color: #2b2b2b1c;
}

.TFw-jG_nobold {
  font-weight: normal !important;
}

.cslm1q_container {
  box-sizing: border-box;
  justify-content: center;
  gap: 48px;
  width: 100%;
  display: flex;
}

@media only screen and (width <= 968px) {
  .cslm1q_container {
    justify-content: center;
    width: 100%;
    height: auto;
  }
}

.O1Vdva_container {
  box-sizing: border-box;
  cursor: pointer;
  background: #2b2b2b0f;
  border-radius: 4px;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35px;
  margin-top: 10px;
  padding: 30px 20px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.O1Vdva_dropdown {
  box-sizing: border-box;
  color: #2b2b2b;
  background: #eae9e7;
  border: 1px solid #b3b3b342;
  border-radius: 4px;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  padding: 21px 18px;
  position: absolute;
  bottom: 100%;
  left: 0;
}

.O1Vdva_dropdown > div, .O1Vdva_icon {
  cursor: pointer;
}

.O1Vdva_username {
  color: #2b2b2b;
  margin-right: 5px;
}

.O1Vdva_twitterSignIn {
  justify-content: center;
  align-items: center;
  display: flex;
}

.O1Vdva_twitterSignIn svg {
  color: #2b2b2b;
}

.O1Vdva_divider {
  color: #2b2b2b;
  cursor: initial;
  border-top: 1px solid #25252524;
  justify-content: center;
  padding-top: 12px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
}

@media only screen and (width <= 945px) {
  .O1Vdva_container {
    margin-top: 6px;
    padding: 25px 20px;
  }
}

.woAJlG_body {
  background: #fff;
  justify-content: center;
  width: 100vw;
  min-height: 100dvh;
  max-height: 100dvh;
  display: flex;
}

.woAJlG_close {
  justify-content: space-between;
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  display: flex;
}

.woAJlG_close h2 {
  margin: 0 0 14px;
}

.woAJlG_close svg {
  cursor: pointer;
}

.woAJlG_address {
  text-overflow: ellipsis;
  max-width: 390px;
  display: block;
  overflow: hidden;
}

.woAJlG_row {
  gap: 8px;
  display: flex;
}

.woAJlG_row b {
  white-space: nowrap;
}

.woAJlG_amount {
  text-overflow: ellipsis;
  max-width: 180px;
  display: block;
  overflow: hidden;
}

.woAJlG_hide {
  color: #00f;
  cursor: pointer;
  text-decoration: underline;
}

.woAJlG_modalContent {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.woAJlG_connectButton {
  cursor: pointer;
  color: #3b3b3b;
  background: none;
  border: 1px solid #59acd6;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  padding: 9px 16px;
  font-size: 16px;
  line-height: 1;
  transition: color .1s;
  display: flex;
}

.woAJlG_twitterSignIn {
  color: #3c4043;
  cursor: pointer;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dadce0;
  border-radius: 4px;
  align-items: center;
  height: 40px;
  padding: 0 12px;
  font-family: Google Sans, arial, sans-serif;
  font-size: 14px;
  transition: all .2s;
  display: flex;
}

.woAJlG_twitterSignIn:hover {
  background: #4285f40a;
  border-color: #d2e3fc;
}

.woAJlG_twitterSignIn svg {
  width: 18px;
  height: 18px;
}

.woAJlG_twitterSignIn > div {
  font-family: Google Sans, arial, sans-serif;
}

.woAJlG_search {
  cursor: pointer;
  color: #3b3b3b;
  background: none;
  border: 1px solid #59acd6;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 20%;
  padding: 9px 16px;
  font-size: 16px;
  transition: color .1s;
  display: flex;
}

.woAJlG_search svg {
  color: #3b3b3b;
  margin-left: 8px;
  transition: color .1s;
}

.woAJlG_input {
  box-sizing: border-box;
  caret-color: #3b3b3b;
  color: #3b3b3b;
  background: #2b2b2b0f;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 80%;
  padding: 10px 0 10px 15px;
  font-size: 16px;
}

.VjrALq_buttons {
  flex-direction: column;
  display: flex;
}

.VjrALq_buttons button:first-of-type {
  margin-top: 0 !important;
}

.VjrALq_buttons button {
  border-radius: 16px;
  height: 36px;
}

.Z5UYwW_buttons {
  flex-direction: column;
  display: flex;
}

.Z5UYwW_buttons button:first-of-type {
  margin-top: 0 !important;
}

.Z5UYwW_buttons button {
  border-radius: 16px;
  height: 36px;
}

.lDmasW_userCount {
  color: #969696;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 300;
}

.xgmUuq_icon {
  cursor: pointer;
}

.p6a3OW_title {
  color: #bebebe;
  font-size: 18px;
  font-weight: 700;
}

.eH6oKa_card {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #b5b5b5;
  border-radius: 14px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  min-height: 122px;
  margin-bottom: 10px;
  padding: 15px;
  display: flex;
}

.eH6oKa_leftSideContainer {
  flex-direction: column;
  display: flex;
}

.eH6oKa_leftSideContainer > span {
  text-overflow: ellipsis;
  width: 145px;
  overflow: hidden;
}

.iS9MAa_title {
  color: #3b3b3b;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.DtNqIq_text {
  color: #3b3b3b;
  font-size: 16px;
  font-weight: 300;
}

.zND1GG_bars {
  width: 160px;
  height: 4px;
  margin-top: 5px;
  display: flex;
}

.zND1GG_bar {
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  transition: transform .3s ease-out;
  display: flex;
}

.zND1GG_bar:hover {
  transform: scale(1.5);
}

.zND1GG_yesBar {
  background: #d24a43;
}

.zND1GG_noBar {
  background: #3eb5f1;
}

._6Ko8iW_stats {
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

._6Ko8iW_textContainer {
  justify-content: center;
  width: 160px;
  display: flex;
}

._6Ko8iW_text {
  justify-content: center;
  margin-top: 5px;
  display: flex;
}

._6Ko8iW_arrowDown {
  border-top: 8px solid #b3b3b3;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
}

._6Ko8iW_img {
  border: 3px solid #b3b3b3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: relative;
}

._6Ko8iW_tester {
  content: "";
  z-index: 111;
  background: #b3b3b3;
  border: 3px solid #b3b3b3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 14px;
  display: none;
  position: absolute;
  bottom: 2px;
}

._6Ko8iW_imgWithArrow {
  position: absolute;
  bottom: 0;
}

._6Ko8iW_imgWithArrow:hover ._6Ko8iW_tester {
  display: flex;
}

@media only screen and (width <= 925px) {
  ._6Ko8iW_stats {
    margin-bottom: 34px;
  }
}

.kTLJjW_button {
  color: #222;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 59px;
  height: 33px;
  font-size: 18px;
  font-weight: 500;
  transition: box-shadow .3s ease-out;
  display: flex;
}

.kTLJjW_button:hover {
  box-shadow: none;
}

.kTLJjW_yes {
  background: #d24a43;
  box-shadow: 0 3px 3.84px 2.16px #ff00002e;
}

.kTLJjW_yes:disabled {
  box-shadow: none;
  cursor: not-allowed;
  background: #af6a66;
}

.kTLJjW_no {
  background: #3eb5f1;
  box-shadow: 0 3px 3.84px 2.16px #3eb5f12e;
}

.kTLJjW_no:disabled {
  box-shadow: none;
  cursor: not-allowed;
  background: #5dabd2;
}

.kTLJjW_noHover.kTLJjW_yes, .kTLJjW_noHover.kTLJjW_no, .kTLJjW_noHover:hover:hover {
  box-shadow: none !important;
}

.rEygXq_answerButtons {
  justify-content: space-between;
  width: 100%;
  padding: 0 23px;
  display: flex;
}

.wLf4MW_card {
  box-sizing: border-box;
  background: none;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  position: relative;
}

.wLf4MW_card:last-of-type {
  margin-bottom: 0;
}

.wLf4MW_previewImg {
  object-fit: cover;
  aspect-ratio: 1 / 2;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 180px;
}

.wLf4MW_innerCard {
  box-sizing: border-box;
  background: none;
  border: 1px solid #b3b3b3;
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 180px;
  padding: 15px;
  display: flex;
  position: relative;
}

.wLf4MW_innerCardWithImage {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.wLf4MW_expand {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: calc(100% - 18px);
  margin-top: 15px;
  padding-top: 10px;
  display: flex;
}

.wLf4MW_expand svg {
  width: auto;
  height: 20px;
}

.wLf4MW_expand .wLf4MW_iconButton {
  border-radius: 12px;
  padding: 7px 13px;
  transition: all .3s;
}

.wLf4MW_expand .wLf4MW_iconButton:hover {
  background: #f1f1f1;
}

.wLf4MW_username {
  color: #00000063;
  cursor: pointer;
  transition: color .2s;
}

.wLf4MW_username:hover {
  color: #3b3b3b;
}

.wLf4MW_hint {
  color: #969696;
  font-size: 16px;
  font-weight: 300;
}

.wLf4MW_icon {
  cursor: pointer;
  transition: all .2s !important;
}

.wLf4MW_icon:hover {
  color: #3b3b3b;
  fill: #3b3b3b;
}

.wLf4MW_selectedQuestion .wLf4MW_innerCardWithImage, .wLf4MW_selectedQuestion .wLf4MW_innerCard {
  border-width: 2px;
  margin-bottom: 30px;
}

.wLf4MW_selectedQuestion .wLf4MW_innerCardWithImage:after, .wLf4MW_selectedQuestion .wLf4MW_innerCard:after {
  content: "⬤";
  color: #bebebe;
  font-size: 6px;
  position: absolute;
  bottom: -25px;
  right: 50%;
  transform: translateX(-50%);
}

.wLf4MW_badge {
  color: #3b3b3b;
  text-align: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
}

.wLf4MW_badge span {
  box-sizing: border-box;
  text-align: center;
  background: #bebebe;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 87px;
  margin-bottom: -3px;
  padding-top: 5px;
  padding-bottom: 7px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
  font-weight: 300;
  display: block;
}

.wLf4MW_selectedQuestion .wLf4MW_previewImg {
  border: 2px solid #b3b3b3;
  border-bottom: none;
}

.wLf4MW_vennDiagramIcon path {
  transition: fill .5s ease-in-out;
}

.wLf4MW_commentText:hover {
  color: #4b4949 !important;
}

.wLf4MW_textarea {
  box-sizing: border-box;
  caret-color: #969696;
  color: #2b2b2b;
  resize: none;
  background: #2b2b2b0f;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 144px;
  padding: 10px 15px;
  font-family: inherit;
  font-size: 16px;
}

.wLf4MW_textarea::placeholder {
  color: #2b2b2b8e;
  font-size: 16px;
}

.wLf4MW_textarea:disabled {
  cursor: not-allowed;
}

.wLf4MW_button {
  cursor: pointer;
  color: #121212;
  background: none;
  border: 1px solid #b5b5b5;
  border-radius: 16px;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 36px;
  font-size: 16px;
  font-weight: 400;
  transition: background .3s;
  display: flex;
  position: absolute;
  bottom: -5px;
  right: 5px;
  transform: translateY(-50%);
}

.wLf4MW_button:not(:disabled):hover {
  cursor: pointer;
  background: #fff;
}

.wLf4MW_button:disabled {
  cursor: not-allowed;
}

.wLf4MW_text {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}

.r56-Eq_textarea {
  box-sizing: border-box;
  caret-color: #969696;
  color: #bebebe;
  resize: none;
  background: #2b2b2b;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 144px;
  padding: 10px 15px;
  font-family: inherit;
  font-size: 18px;
}

.r56-Eq_textarea::placeholder {
  color: #969696;
  opacity: 1;
  font-size: 18px;
}

.r56-Eq_textarea:disabled {
  cursor: not-allowed;
}

.r56-Eq_text {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}

._84V7Lq_newQuestion {
  width: 100%;
  margin-top: 25px;
  position: relative;
}

._84V7Lq_newQuestion textarea {
  color: #121212;
  background: #f3f3f3;
  font-size: 16px;
}

._84V7Lq_newQuestion textarea::placeholder {
  color: #2b2b2b8e;
  font-size: 16px;
}

@media only screen and (width <= 400px) {
  ._84V7Lq_newQuestion {
    margin-top: 14px;
  }
}

@media only screen and (width <= 945px) {
  ._84V7Lq_newQuestion textarea {
    height: 110px !important;
  }
}

._84V7Lq_button {
  cursor: pointer;
  color: #121212;
  background: none;
  border: 1px solid #b5b5b5;
  border-radius: 16px;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 36px;
  font-size: 16px;
  font-weight: 400;
  transition: background .3s;
  display: flex;
  position: absolute;
  bottom: -5px;
  right: 5px;
  transform: translateY(-50%);
}

._84V7Lq_button:not(:disabled):hover {
  background: #fff;
}

._84V7Lq_button:disabled {
  cursor: not-allowed;
}

.qpgnKq_groupContainer {
  flex-direction: column;
  width: 100%;
  height: calc(100% - 144px);
  display: flex;
  overflow: scroll;
}

.PTna1q_container {
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  display: flex;
}

.ntmV-a_mainScreen {
  flex-direction: column;
  align-items: center;
  width: 500px;
  display: flex;
}

.ntmV-a_mainScreenContainer {
  flex-direction: column;
}

._5_71Va_sidebar {
  flex-direction: column;
  align-items: center;
  width: 380px;
  display: flex;
  overflow-y: scroll;
}

@media only screen and (width <= 400px) {
  ._5_71Va_sidebar {
    width: 100%;
  }
}

._5_71Va_title {
  margin-bottom: 25px;
}

.r0fyZq_cardsRow {
  flex-direction: column;
  align-items: center;
  width: 439px;
  display: flex;
}

.r0fyZq_scrollContainer {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
}

.dqFlAW_back {
  color: #3b3b3b;
  cursor: pointer;
  padding: 3px;
  position: relative;
}

.dqFlAW_screenWithGroupContent {
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
  width: calc(100% - 20px);
  max-width: 500px;
  height: calc(100dvh - 20px);
  margin-top: 10px;
  display: flex;
  overflow: scroll;
}

.dqFlAW_usersContainer {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.dqFlAW_screenWithGroupContent > p {
  color: #bebebe;
  padding: 30px 15px;
}

@media only screen and (width <= 945px) {
  .dqFlAW_screenWithGroupContent {
    display: none;
  }
}

.dqFlAW_userItem {
  color: #3b3b3b;
  cursor: pointer;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  flex-direction: column;
  gap: 24px;
  padding: 15px;
  display: flex;
}

.dqFlAW_userItem img, .dqFlAW_userItem .dqFlAW_imgFallback {
  border: 3px solid #d9d7d5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.dqFlAW_userItem svg {
  color: #3b3b3b;
}

.dqFlAW_userItem a {
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  width: 38px;
  height: 38px;
  padding: 6px;
  transition: box-shadow .2s;
}

.dqFlAW_userItem a:hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}

.dqFlAW_userItem .dqFlAW_x-icon {
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  padding: 6px;
  transition: box-shadow .2s;
  width: 38px !important;
  height: 38px !important;
}

.dqFlAW_userItem .dqFlAW_x-icon:hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}

.dqFlAW_column {
  flex-direction: column;
  gap: 3px;
  display: flex;
}

.dqFlAW_row {
  gap: 8px;
  display: flex;
}

.dqFlAW_close {
  box-sizing: border-box;
  justify-content: flex-end;
  width: 100%;
  display: flex;
}

.dqFlAW_close h2 {
  margin: 0 0 20px;
}

.dqFlAW_close svg {
  cursor: pointer;
}

.dqFlAW_checkboxes__row {
  display: flex;
}

.dqFlAW_checkboxes__item {
  padding: 15px;
}

.dqFlAW_checkbox.dqFlAW_style-c {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding-left: 30px;
  display: inline-block;
  position: relative;
}

.dqFlAW_checkbox.dqFlAW_style-c input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
}

.dqFlAW_checkbox.dqFlAW_style-c input:checked ~ .dqFlAW_checkbox__checkmark {
  background-color: #f7cb15;
}

.dqFlAW_checkbox.dqFlAW_style-c input:checked ~ .dqFlAW_checkbox__checkmark:after {
  opacity: 1;
}

.dqFlAW_checkbox.dqFlAW_style-c:hover input ~ .dqFlAW_checkbox__checkmark {
  background-color: #eee;
}

.dqFlAW_checkbox.dqFlAW_style-c:hover input:checked ~ .dqFlAW_checkbox__checkmark {
  background-color: #f7cb15;
}

.dqFlAW_checkbox.dqFlAW_style-c .dqFlAW_checkbox__checkmark {
  background-color: #eee;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  transition: background-color .25s;
  position: absolute;
  top: 2px;
  left: 0;
}

.dqFlAW_checkbox.dqFlAW_style-c .dqFlAW_checkbox__checkmark:after {
  content: "";
  opacity: 0;
  border: 2px solid #333;
  border-width: 0 2px 2px 0;
  width: 5px;
  height: 10px;
  transition: opacity .25s;
  position: absolute;
  top: 4px;
  left: 8px;
  transform: rotate(45deg);
}

.dqFlAW_checkbox.dqFlAW_style-c .dqFlAW_checkbox__body {
  color: #333;
  align-items: center;
  font-size: 16px;
  line-height: 1.4;
  display: flex;
}

.react-tooltip {
  top: 0;
  background-color: #000 !important;
}

.eGpACq_back {
  color: #3b3b3b;
  cursor: pointer;
  padding: 3px;
  position: relative;
}

.eGpACq_backContainer {
  justify-content: end;
  width: 100%;
  display: flex;
}

.eGpACq_screenWithGroupContent {
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 20px);
  max-width: 500px;
  height: calc(100dvh - 20px);
  margin-top: 10px;
  font-family: IBM Plex Sans;
  display: flex;
  overflow: scroll;
}

.eGpACq_screenWithGroupContent .eGpACq_button {
  cursor: pointer;
  color: #3b3b3b;
  background: none;
  border: 1px solid #59acd6;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 9px 16px;
  font-size: 16px;
  line-height: 1;
  transition: box-shadow .2s;
  display: flex;
}

.eGpACq_screenWithGroupContent .eGpACq_button:hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}

.eGpACq_screenWithGroupContent .eGpACq_button svg {
  color: #3b3b3b;
  margin-left: 8px;
  transition: color .1s;
}

.eGpACq_similarity {
  color: #3b3b3b;
  text-align: center;
  border-top: 1px solid #b3b3b3;
  margin: 0;
  padding-top: 24px;
  padding-bottom: 9px;
  font-size: 36px;
  font-weight: 200;
}

.eGpACq_similarity span {
  font-weight: 400;
}

.eGpACq_tabs {
  background: #2b2b2b0f;
  border-radius: 4px;
  gap: 12px;
  padding: 4px;
  display: flex;
}

.eGpACq_tabs div {
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 5px 10px;
  transition: background-color .1s;
  display: flex;
}

.eGpACq_tabs div.eGpACq_selected {
  background-color: #2b2b2b14;
}

.eGpACq_differentTabs {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
}

.eGpACq_differentTabs2 {
  width: 80%;
  margin: 0 auto;
}

.rrbiRW_input {
  box-sizing: border-box;
  caret-color: #3b3b3b;
  color: #3b3b3b;
  background: #2b2b2b0f;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 36px;
  padding: 10px 0 10px 15px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
}

.rrbiRW_search {
  cursor: pointer;
  color: #3b3b3b;
  background: none;
  border: 1px solid #59acd6;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  padding: 9px 16px;
  font-size: 16px;
  line-height: 1;
  transition: color .1s;
  display: flex;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.rrbiRW_search svg {
  color: #3b3b3b;
  margin-left: 8px;
  transition: color .1s;
}

.rrbiRW_text {
  color: #bebebe;
  margin-bottom: 24px;
  font-size: 16px;
  display: flex;
  position: relative;
}

.rrbiRW_dropdownContainer {
  box-sizing: border-box;
  width: 138px;
  font-size: 16px;
  transition: color .1s;
  position: absolute;
  top: 50%;
  right: 95px;
  transform: translateY(-50%);
}

.rrbiRW_dropdownContainer svg {
  color: #3b3b3b;
  cursor: pointer;
  height: 18px;
  transition: color .1s;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.rrbiRW_dropdownValue {
  cursor: pointer;
  color: #3b3b3b;
  box-sizing: border-box;
  background: none;
  border: 1px solid #59acd6;
  border-radius: 4px;
  padding: 9px 40px 9px 16px;
  line-height: 1;
  transition: color .1s;
}

.rrbiRW_dropdownItemsContainer {
  box-sizing: border-box;
  color: #3b3b3b;
  z-index: 999;
  cursor: pointer;
  background: #f4f3fd;
  border-radius: 4px;
  flex-direction: column;
  width: 100%;
  margin-top: -3px;
  padding: 0 4px;
  transition: color .1s;
  display: flex;
  position: absolute;
  bottom: 0;
  box-shadow: 0 3px 3.84px 1.16px #0c0c0c2b;
}

.rrbiRW_dropdownItemsContainer > div {
  border-top: 1px solid #3b3b3b21;
  padding: 9px 0 9px 16px;
}

.rrbiRW_dropdownItemsContainer > div span {
  margin-top: 1px;
  margin-left: -3px;
  display: block;
}

.rrbiRW_dropdownItemsContainer > div:first-child {
  border-top: none;
}

.rrbiRW_buttonsOutside {
  margin-top: 10px;
  flex-direction: column !important;
  gap: 8px !important;
}

.rrbiRW_buttonsOutside .rrbiRW_dropdownValue {
  border: none;
}

.rrbiRW_buttonsOutside .rrbiRW_dropdownContainer {
  border-radius: 4px;
  top: 0;
  right: 0;
  position: relative !important;
}

.rrbiRW_buttonsOutside .rrbiRW_dropdownContainer, .rrbiRW_buttonsOutside .rrbiRW_search {
  background: #2b2b2b0f;
  border: none;
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  transform: none;
}

@media only screen and (width <= 386px) {
  .rrbiRW_text {
    flex-direction: column;
    gap: 8px;
  }

  .rrbiRW_dropdownValue {
    border: none;
  }

  .rrbiRW_dropdownContainer {
    border-radius: 4px;
    top: 0;
    right: 0;
    position: relative !important;
  }

  .rrbiRW_dropdownContainer, .rrbiRW_search {
    background: #f4f3fd;
    border: none;
    width: 100%;
    position: static;
    transform: none;
  }
}

@media only screen and (width <= 945px) {
  .rrbiRW_text {
    font-size: 16px;
  }

  .rrbiRW_text button {
    color: #f4f3fd;
    font-size: 16px;
    background: #3b3b3b !important;
  }

  .rrbiRW_text button svg {
    color: #f4f3fd;
  }

  .rrbiRW_text input {
    color: #f4f3fd;
    caret-color: #f4f3fd;
    background: #3b3b3b;
    height: 36px;
    font-size: 16px;
  }

  .rrbiRW_text input::placeholder {
    color: #f4f3fd;
  }

  .rrbiRW_text .rrbiRW_dropdownContainer {
    color: #f4f3fd;
    background: #3b3b3b;
  }

  .rrbiRW_text .rrbiRW_dropdownContainer svg, .rrbiRW_text .rrbiRW_dropdownValue, .rrbiRW_text .rrbiRW_dropdownValue svg {
    color: #f4f3fd;
  }
}

._25x40a_totalAndBack {
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  display: flex;
}

._25x40a_total {
  color: #3b3b3b;
  font-family: IBM Plex Sans;
  font-size: 18px;
  font-weight: 300;
}

._25x40a_back {
  color: #3b3b3b;
  cursor: pointer;
  padding: 3px;
  position: relative;
}

._25x40a_screenWithGroupContent {
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 20px);
  max-width: 500px;
  height: calc(100dvh - 20px);
  display: flex;
  overflow: scroll;
}

.zrOIxW_back {
  color: #3b3b3b;
  cursor: pointer;
  padding: 3px;
  position: relative;
}

.zrOIxW_totalAndBack {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.zrOIxW_total {
  color: #3b3b3b;
  font-family: IBM Plex Sans;
  font-size: 18px;
  font-weight: 300;
}

.zrOIxW_usersContainer {
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 20px);
  max-width: 500px;
  height: calc(100dvh - 20px);
  margin-top: 10px;
  display: flex;
  overflow: scroll;
}

.zrOIxW_usersContainer > p {
  color: #3b3b3b;
  padding: 30px 15px;
}

.zrOIxW_userItem {
  color: #3b3b3b;
  cursor: pointer;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  justify-content: space-between;
  gap: 12px;
  padding: 15px;
  display: flex;
}

.zrOIxW_userItem img {
  border: 3px solid #d9d7d5;
  border-radius: 50%;
  height: 50px;
}

.zrOIxW_userItem svg {
  color: #3b3b3b;
}

.zrOIxW_userItem a {
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  width: 38px;
  height: 38px;
  padding: 6px;
  transition: box-shadow .2s;
}

.zrOIxW_userItem a:hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}

.zrOIxW_column {
  flex-direction: column;
  display: flex;
}

.zrOIxW_row {
  gap: 8px;
  display: flex;
}

.zrOIxW_usersItems {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.rUSHRq_text {
  color: #bebebe;
  flex-direction: column;
  gap: 16px;
  margin-top: 64px;
  margin-bottom: 24px;
  font-size: 16px;
  display: flex;
  position: relative;
}

.rUSHRq_text:before {
  content: "⬤";
  font-size: 6px;
  position: absolute;
  top: -32px;
  right: 50%;
  transform: translateX(-50%);
}

.rUSHRq_dropdownContainer {
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 241px;
  transition: color .1s;
  position: relative;
}

.rUSHRq_dropdownContainer svg {
  color: #3b3b3b;
  cursor: pointer;
  height: 18px;
  transition: color .1s;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.rUSHRq_dropdownValue {
  cursor: pointer;
  color: #3b3b3b;
  box-sizing: border-box;
  white-space: nowrap;
  background: #2b2b2b0f;
  border: none;
  border-radius: 4px;
  padding: 9px 40px 9px 16px;
  line-height: 1;
  transition: color .1s;
}

.rUSHRq_dropdownItemsContainer {
  box-sizing: border-box;
  color: #3b3b3b;
  z-index: 999;
  cursor: pointer;
  background: #f4f3fd;
  border-radius: 4px;
  flex-direction: column;
  width: 100%;
  margin-top: -3px;
  padding: 0 4px;
  transition: color .1s;
  display: flex;
  position: absolute;
  bottom: 0;
  box-shadow: 0 3px 3.84px 1.16px #0c0c0c2b;
}

.rUSHRq_dropdownItemsContainer > div {
  border-top: 1px solid #3b3b3b21;
  padding: 9px 0 9px 16px;
}

.rUSHRq_dropdownItemsContainer > div span {
  margin-top: 1px;
  margin-left: -3px;
  display: block;
}

.rUSHRq_dropdownItemsContainer > div:first-child {
  border-top: none;
}

.rUSHRq_dropdownWithLabel {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

@media only screen and (width <= 386px) {
  .rUSHRq_text {
    flex-direction: column;
    gap: 8px;
  }

  .rUSHRq_dropdownValue {
    border: none;
  }

  .rUSHRq_dropdownContainer {
    border-radius: 4px;
    top: 0;
    right: 0;
    position: relative !important;
  }

  .rUSHRq_dropdownContainer, .rUSHRq_search {
    background: #f4f3fd;
    border: none;
    width: 100%;
    position: static;
    transform: none;
  }
}

@media only screen and (width <= 945px) {
  .rUSHRq_text {
    font-size: 16px;
  }

  .rUSHRq_text span {
    color: #3b3b3b;
    font-weight: 300;
  }

  .rUSHRq_text .rUSHRq_dropdownContainer {
    color: #f4f3fd !important;
    background: #3b3b3b !important;
  }

  .rUSHRq_text .rUSHRq_dropdownContainer svg, .rUSHRq_text .rUSHRq_dropdownValue {
    color: #f4f3fd !important;
  }
}

.lN8-WW_card {
  box-sizing: border-box;
  background: none;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100dvh;
  margin-bottom: 10px;
  padding: 15px 0 10px;
  display: flex;
  overflow: hidden;
}

.lN8-WW_card:last-of-type {
  margin-bottom: 0;
}

.lN8-WW_username {
  color: #00000063;
  white-space: pre;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
  line-height: 1;
  transition: color .2s;
  display: flex;
}

.lN8-WW_usernameActual {
  cursor: pointer;
}

.lN8-WW_usernameActual:hover {
  color: #3b3b3b;
}

.lN8-WW_hint {
  color: #969696;
  font-size: 16px;
  font-weight: 300;
}

.lN8-WW_stats {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.lN8-WW_stats > div {
  width: 100%;
}

.lN8-WW_textContainer {
  justify-content: center;
  width: 160px;
  margin-top: 24px;
  display: flex;
}

.lN8-WW_text {
  justify-content: center;
  margin-top: 5px;
  display: flex;
}

.lN8-WW_questionText {
  font-size: 178px;
  font-weight: 200;
}

.lN8-WW_respondButton {
  color: #3b3b3b;
  cursor: pointer;
  background: none;
  border: none;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  display: flex;
}

.lN8-WW_respondButton svg {
  color: #3b3b3b;
}

.lN8-WW_arrowDown {
  border-top: 13px solid #b3b3b3;
  border-left: 13px solid #0000;
  border-right: 13px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.lN8-WW_img {
  z-index: 99999;
  border: 3px solid #b3b3b3;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
}

.lN8-WW_imgWithArrow {
  margin-bottom: 4px;
  position: relative;
}

.lN8-WW_bars {
  height: 14px;
}

.lN8-WW_barsMinWidth > div {
  min-width: 102px;
}

.lN8-WW_close {
  box-sizing: border-box;
  justify-content: flex-end;
  width: 100%;
  padding-right: 24px;
  display: flex;
}

.lN8-WW_close h2 {
  margin: 0 0 20px;
}

.lN8-WW_close svg {
  cursor: pointer;
}

.svA8qW_chat {
  width: 500px;
  padding: 10px 0;
}

.svA8qW_messageBoard {
  flex-flow: column wrap;
  width: 100%;
  height: calc(100dvh - 254px);
  display: flex;
  overflow-y: scroll;
}

.svA8qW_messageBoard::-webkit-scrollbar {
  display: none;
}

.svA8qW_message {
  background-color: #f7f7f7;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  width: 50%;
  min-width: 300px;
  max-width: 500px;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
}

.svA8qW_message:last-child {
  margin-bottom: 0;
}

.svA8qW_mine {
  align-self: flex-end;
}

.svA8qW_notMine {
  align-self: flex-start;
}

.svA8qW_inputGroup {
  z-index: 1;
  flex-flow: row;
  justify-content: center;
  margin-bottom: 10px;
  padding: 0 8px;
  font-size: 1.14em;
  display: flex;
  position: relative;
  bottom: 0;
}

.svA8qW_input {
  color: #2b2b2b;
  background: #2b2b2b0f;
  border: 0;
  border-radius: 4px;
  outline: 0;
  flex-grow: 1;
  height: 144px;
  font-size: 16px;
}

.svA8qW_input:focus {
  background-color: #ffffffbf;
}

.svA8qW_input::-webkit-input-placeholder {
  color: #21212140;
}

.svA8qW_btn {
  color: #f1f1f1;
  cursor: pointer;
  background: #3eb5f1;
  border: none;
  border-radius: 29px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  font-size: 18px;
  font-weight: 500;
  transition: box-shadow .3s ease-out;
  display: flex;
  position: absolute;
  bottom: -16px;
  right: 16px;
  transform: translateY(-50%);
  box-shadow: 0 0 23.84px 8.16px #3eb5f163;
}

.svA8qW_btn:hover {
  border-bottom-color: #1e528f;
}

.svA8qW_picture {
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 50%;
  height: 36px;
  margin-right: 15px;
}

.svA8qW_changeGroupBtn {
  color: #f1f1f1;
  cursor: pointer;
  background: #3eb5f1;
  border: none;
  border-radius: 4px;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 40px;
  font-size: 18px;
  font-weight: 500;
  transition: box-shadow .3s ease-out;
  display: flex;
  box-shadow: 0 3px 3.84px 2.16px #3eb5f12e;
}

.svA8qW_top {
  border-bottom: 1px solid #000;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 20px;
  display: flex;
}

.svA8qW_currentGroup {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.YjWlYa_landing {
  text-align: center;
  width: 100%;
  font-family: Playfair Display, sans-serif;
  font-weight: 500;
  position: relative;
}

.YjWlYa_svgWrapper {
  width: 25px;
  height: 18px;
}

.YjWlYa_svgWrapper svg {
  width: auto;
  height: 18px;
  padding-right: 6px;
}

.YjWlYa_landing .YjWlYa_name {
  color: #fff;
  white-space: nowrap;
  font-family: Playfair Display, sans-serif;
  font-size: 50px;
}

h1, h2 {
  margin: 0;
}

.YjWlYa_landing .YjWlYa_subtitle {
  color: #fff;
  white-space: nowrap;
  font-family: Playfair Display, sans-serif;
  font-size: 40px;
  font-weight: 300;
}

@keyframes YjWlYa_scrollWheel {
  0% {
    opacity: 1;
    transform: translateY(40%);
  }

  100% {
    opacity: .7;
    transform: translateY(130%);
  }
}

@keyframes YjWlYa_scrollText {
  0% {
    opacity: 1;
    transform: translateY(120%);
  }

  100% {
    opacity: 0;
    transform: translateY(180%);
  }
}

.YjWlYa_open-app-button {
  letter-spacing: -.5px;
  box-sizing: border-box;
  -webkit-text-decoration: unset;
  -webkit-text-decoration: unset;
  text-decoration: unset;
  color: #f1f1f1;
  cursor: pointer;
  background: #000;
  border: none;
  border-radius: 29px;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 160px;
  margin: 32px auto 0;
  padding: 12px 18px;
  font-family: Playfair Display, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  transition: box-shadow .3s ease-out;
  display: flex;
  box-shadow: 0 0 23.84px 8.16px #3eb5f163;
}

.YjWlYa_open-app-button:hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}

.YjWlYa_privacy {
  letter-spacing: 0;
  color: #ffffff86;
  white-space: nowrap;
  font-weight: 200;
  line-height: 15px;
  font-family: IBM Plex Sans !important;
}

.YjWlYa_privacy a {
  color: inherit;
  font-size: 15px;
  text-decoration: none;
}

.YjWlYa_made-by {
  letter-spacing: 0;
  white-space: nowrap;
  color: #ffffff86;
  font-size: 14px;
  font-weight: 200;
  line-height: 14px;
  font-family: IBM Plex Sans !important;
}

.YjWlYa_made-by span {
  margin-right: 2px;
}

.YjWlYa_made-by a {
  color: inherit;
  font-family: Dancing Script;
  font-size: 20px;
  text-decoration: none;
}

.YjWlYa_icons {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 22px;
  font-size: 15px;
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

@media only screen and (width <= 843px) {
  .YjWlYa_landing .YjWlYa_text {
    gap: 4px;
  }

  .YjWlYa_landing .YjWlYa_name {
    font-size: 42px;
  }

  .YjWlYa_landing .YjWlYa_subtitle {
    font-size: 33px;
  }
}

@media only screen and (width <= 640px) {
  .YjWlYa_landing .YjWlYa_text {
    gap: 4px;
  }

  .YjWlYa_landing .YjWlYa_name {
    white-space: normal;
    margin-bottom: 14px;
    font-size: 37px;
  }

  .YjWlYa_landing .YjWlYa_subtitle {
    white-space: normal;
    font-size: 23px;
  }

  .YjWlYa_icons {
    display: none;
  }
}

.YjWlYa_icon {
  color: #fff;
  z-index: 101;
  background: #06f;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.YjWlYa_bg {
  filter: brightness(60%);
  background-image: url("bg-dark.7e564ff1.ba672445.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
}

.YjWlYa_content {
  width: calc(100% - 20px);
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
}

.qk656W_container {
  box-sizing: border-box;
  width: 100%;
  max-width: 480px;
  margin: 40px auto 0;
  padding: 24px 16px;
}

.qk656W_title {
  text-align: center;
  color: #fff;
  text-shadow: 0 1px 3px #0009;
  letter-spacing: .5px;
  margin-bottom: 12px;
  font-size: 22px;
}

.qk656W_progressWrapper {
  border-radius: 9999px;
  height: 28px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 6px #00000040;
}

.qk656W_progressBlurBackground {
  backdrop-filter: blur(6px);
  z-index: 1;
  background: #fff3;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.qk656W_progressFill {
  z-index: 2;
  background: linear-gradient(to right, #f9c, #f67);
  border-radius: 9999px;
  height: 100%;
  transition: width .5s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.qk656W_progressText {
  z-index: 3;
  text-align: center;
  color: #fff;
  text-shadow: 0 1px 3px #0009;
  width: 100%;
  font-size: 18px;
  position: relative;
}

.qk656W_second-type.qk656W_container {
  margin-top: 0;
}

.qk656W_second-type {
  width: 100%;
}

.qk656W_second-type * {
  color: #121212;
  text-shadow: none !important;
  box-shadow: none !important;
}

.qk656W_second-type .qk656W_progressWrapper {
  border: 1px solid #121212;
}

.qk656W_second-type .qk656W_title {
  font-size: 21px;
}

.qk656W_second-type .qk656W_progressText {
  font-size: 16px;
}

.canA9G_leftbar {
  z-index: 99999;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100vh;
  padding: 15px 0;
  display: flex;
  position: fixed;
  left: 0;
}

.canA9G_avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-top: 15px;
  overflow: hidden;
}

.canA9G_avatar svg {
  color: #aaa;
  margin-left: 2px;
}

.canA9G_avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.canA9G_nav-icons {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.canA9G_nav-item {
  color: #aaa;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 18px;
  transition: all .3s;
  display: flex;
}

.canA9G_nav-item:hover {
  background: #f1f1f1;
}

.canA9G_nav-item.canA9G_active {
  color: #000;
  fill: #000 !important;
}

.canA9G_nav-item.canA9G_active svg {
  fill: #000 !important;
}

@media screen and (width <= 945px) {
  .canA9G_leftbar {
    flex-direction: row;
    width: 100%;
    height: 60px;
    bottom: 0;
  }

  .canA9G_nav-icons {
    flex-direction: row;
  }

  .canA9G_nav-item {
    margin-top: 0;
  }

  .canA9G_avatar {
    margin-left: 15px;
  }
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

:root, [data-theme] {
  color: hsl(var(--heroui-foreground));
  background-color: hsl(var(--heroui-background));
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-right-2 {
  right: -.5rem;
}

.-right-px {
  right: -1px;
}

.-top-2 {
  top: -.5rem;
}

.-top-px {
  top: -1px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[10\%\] {
  bottom: 10%;
}

.bottom-\[5\%\] {
  bottom: 5%;
}

.end-1:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: .25rem;
}

.end-1:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: .25rem;
}

.end-1:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: .25rem;
}

.end-1\.5:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: .375rem;
}

.end-1\.5:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: .375rem;
}

.end-1\.5:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: .375rem;
}

.end-3:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: .75rem;
}

.end-3:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: .75rem;
}

.end-3:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: .75rem;
}

.end-auto:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: auto;
}

.end-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: auto;
}

.end-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: auto;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-\[10\%\] {
  left: 10%;
}

.left-\[5\%\] {
  left: 5%;
}

.left-\[calc\(37\.5\%\)\] {
  left: 37.5%;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.right-\[10\%\] {
  right: 10%;
}

.right-\[5\%\] {
  right: 5%;
}

.start-0:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.start-1\.5:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: .375rem;
}

.start-1\.5:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: .375rem;
}

.start-1\.5:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: .375rem;
}

.start-2:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: .5rem;
}

.start-2:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: .5rem;
}

.start-2:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: .5rem;
}

.start-3:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: .75rem;
}

.start-3:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: .75rem;
}

.start-3:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: .75rem;
}

.start-auto:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: auto;
}

.start-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: auto;
}

.start-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: auto;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-\[10\%\] {
  top: 10%;
}

.top-\[5\%\] {
  top: 5%;
}

.top-\[calc\(100\%_\+_2px\)\] {
  top: calc(100% + 2px);
}

.top-\[calc\(46\%\)\] {
  top: 46%;
}

.top-\[var\(--navbar-height\)\] {
  top: var(--navbar-height);
}

.-z-30 {
  z-index: -30;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[1\] {
  z-index: 1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.-m-2 {
  margin: -.5rem;
}

.-m-2\.5 {
  margin: -.625rem;
}

.m-0 {
  margin: 0;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-\[calc\(\(theme\(spacing\.5\)-theme\(spacing\.1\)\)\/2\)\] {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-\[calc\(\(theme\(spacing\.6\)-theme\(spacing\.3\)\)\/2\)\] {
  margin-left: .375rem;
  margin-right: .375rem;
}

.mx-\[calc\(\(theme\(spacing\.7\)-theme\(spacing\.5\)\)\/2\)\] {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-\[calc\(\(theme\(spacing\.5\)-theme\(spacing\.1\)\)\/2\)\] {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-\[calc\(\(theme\(spacing\.6\)-theme\(spacing\.3\)\)\/2\)\] {
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.my-\[calc\(\(theme\(spacing\.7\)-theme\(spacing\.5\)\)\/2\)\] {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-ms-2:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: -.5rem;
}

.-ms-2:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: -.5rem;
}

.-ms-2:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: -.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-px {
  margin-bottom: 1px;
}

.me-2:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.me-2:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.me-2:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.me-4:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.me-4:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: 1rem;
}

.me-4:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: 1rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ms-2:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.ms-2:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.ms-2:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.hidden {
  display: none;
}

.size-1 {
  width: .25rem;
  height: .25rem;
}

.size-1\.5 {
  width: .375rem;
  height: .375rem;
}

.size-2 {
  width: .5rem;
  height: .5rem;
}

.\!h-auto {
  height: auto !important;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[--visual-viewport-height\] {
  height: var(--visual-viewport-height);
}

.h-\[100dvh\] {
  height: 100dvh;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[50\%\] {
  height: 50%;
}

.h-\[8\%\] {
  height: 8%;
}

.h-\[calc\(100dvh_-_var\(--navbar-height\)\)\] {
  height: calc(100dvh - var(--navbar-height));
}

.h-\[var\(--navbar-height\)\] {
  height: var(--navbar-height);
}

.h-\[var\(--picker-height\)\] {
  height: var(--picker-height);
}

.h-auto {
  height: auto;
}

.h-divider {
  height: var(--heroui-divider-weight);
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-\[20rem\] {
  max-height: 20rem;
}

.max-h-\[24rem\] {
  max-height: 24rem;
}

.max-h-\[28rem\] {
  max-height: 28rem;
}

.max-h-\[32rem\] {
  max-height: 32rem;
}

.max-h-\[36rem\] {
  max-height: 36rem;
}

.max-h-\[42rem\] {
  max-height: 42rem;
}

.max-h-\[48rem\] {
  max-height: 48rem;
}

.max-h-\[56rem\] {
  max-height: 56rem;
}

.max-h-\[64rem\] {
  max-height: 64rem;
}

.max-h-\[calc\(100\%_-_8rem\)\] {
  max-height: calc(100% - 8rem);
}

.max-h-\[none\] {
  max-height: none;
}

.max-h-full {
  max-height: 100%;
}

.min-h-10 {
  min-height: 2.5rem;
}

.min-h-12 {
  min-height: 3rem;
}

.min-h-14 {
  min-height: 3.5rem;
}

.min-h-16 {
  min-height: 4rem;
}

.min-h-3 {
  min-height: .75rem;
}

.min-h-3\.5 {
  min-height: .875rem;
}

.min-h-4 {
  min-height: 1rem;
}

.min-h-5 {
  min-height: 1.25rem;
}

.min-h-6 {
  min-height: 1.5rem;
}

.min-h-7 {
  min-height: 1.75rem;
}

.min-h-8 {
  min-height: 2rem;
}

.min-h-\[100dvh\] {
  min-height: 100dvh;
}

.min-h-\[32px\] {
  min-height: 32px;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[25\%\] {
  width: 25%;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[calc\(100\%_-_16px\)\] {
  width: calc(100% - 16px);
}

.w-\[calc\(100\%_-_theme\(spacing\.6\)\)\] {
  width: calc(100% - 1.5rem);
}

.w-\[calc\(var\(--visible-months\)_\*_var\(--calendar-width\)\)\] {
  width: calc(var(--visible-months) * var(--calendar-width));
}

.w-auto {
  width: auto;
}

.w-divider {
  width: var(--heroui-divider-weight);
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.min-w-10 {
  min-width: 2.5rem;
}

.min-w-12 {
  min-width: 3rem;
}

.min-w-16 {
  min-width: 4rem;
}

.min-w-20 {
  min-width: 5rem;
}

.min-w-24 {
  min-width: 6rem;
}

.min-w-3 {
  min-width: .75rem;
}

.min-w-3\.5 {
  min-width: .875rem;
}

.min-w-4 {
  min-width: 1rem;
}

.min-w-40 {
  min-width: 10rem;
}

.min-w-5 {
  min-width: 1.25rem;
}

.min-w-6 {
  min-width: 1.5rem;
}

.min-w-7 {
  min-width: 1.75rem;
}

.min-w-8 {
  min-width: 2rem;
}

.min-w-9 {
  min-width: 2.25rem;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.min-w-min {
  min-width: min-content;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-\[1024px\] {
  max-width: 1024px;
}

.max-w-\[1280px\] {
  max-width: 1280px;
}

.max-w-\[1536px\] {
  max-width: 1536px;
}

.max-w-\[270px\] {
  max-width: 270px;
}

.max-w-\[640px\] {
  max-width: 640px;
}

.max-w-\[768px\] {
  max-width: 768px;
}

.max-w-\[none\] {
  max-width: none;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.basis-0 {
  flex-basis: 0;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.origin-center {
  transform-origin: center;
}

.origin-left {
  transform-origin: 0;
}

.origin-right {
  transform-origin: 100%;
}

.origin-top {
  transform-origin: top;
}

.origin-top-left {
  transform-origin: 0 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1 {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1 {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/2, .translate-y-2\/4 {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-3\/4 {
  --tw-translate-y: 75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes appearance-in {
  0% {
    opacity: 0;
    transform: translateZ(0)scale(.95);
  }

  60% {
    opacity: .75;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    webkit-font-smoothing: antialiased;
    transform: translateZ(0)scale(1.05);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)scale(1);
  }
}

.animate-\[appearance-in_1s_infinite\] {
  animation: 1s infinite appearance-in;
}

@keyframes blink {
  0% {
    opacity: .2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: .2;
  }
}

.animate-blink {
  animation: 1.4s infinite both blink;
}

@keyframes drip-expand {
  0% {
    opacity: .2;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-drip-expand {
  animation: .42s linear drip-expand;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: .15;
  }
}

.animate-fade-out {
  animation: 1.2s linear infinite fade-out;
}

@keyframes indeterminate-bar {
  0% {
    transform: translateX(-50%)scaleX(.2);
  }

  100% {
    transform: translateX(100%)scaleX(1);
  }
}

.animate-indeterminate-bar {
  animation: 1.5s cubic-bezier(.65, .815, .735, .395) infinite indeterminate-bar;
}

.animate-none {
  animation: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

@keyframes spinner-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-spinner-ease-spin {
  animation: .8s infinite spinner-spin;
}

.animate-spinner-linear-spin {
  animation: .8s linear infinite spinner-spin;
}

@keyframes sway {
  0% {
    transform: translate(0);
  }

  50% {
    transform: translate(0, -150%);
  }

  100% {
    transform: translate(0);
  }
}

.animate-sway {
  animation: .75s infinite sway;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.touch-none {
  touch-action: none;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.snap-y {
  scroll-snap-type: y var(--tw-scroll-snap-strictness);
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-center {
  scroll-snap-align: center;
}

.scroll-py-6 {
  scroll-padding-top: 1.5rem;
  scroll-padding-bottom: 1.5rem;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.\!gap-0 {
  gap: 0 !important;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-0 {
  column-gap: 0;
}

.gap-x-0\.5 {
  column-gap: .125rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-0 {
  row-gap: 0;
}

.gap-y-1\.5 {
  row-gap: .375rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.\!rounded-none {
  border-radius: 0 !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[calc\(theme\(borderRadius\.large\)\/1\.5\)\] {
  border-radius: calc(var(--heroui-radius-large) / 1.5);
}

.rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.5\)\] {
  border-radius: calc(var(--heroui-radius-medium) * .5);
}

.rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.6\)\] {
  border-radius: calc(var(--heroui-radius-medium) * .6);
}

.rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.7\)\] {
  border-radius: calc(var(--heroui-radius-medium) * .7);
}

.rounded-\[calc\(theme\(borderRadius\.medium\)\/2\)\] {
  border-radius: calc(var(--heroui-radius-medium) / 2);
}

.rounded-\[calc\(theme\(borderRadius\.small\)\/2\)\] {
  border-radius: calc(var(--heroui-radius-small) / 2);
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-large {
  border-radius: var(--heroui-radius-large);
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-medium {
  border-radius: var(--heroui-radius-medium);
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-small {
  border-radius: var(--heroui-radius-small);
}

.\!rounded-e-none:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.\!rounded-e-none:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.\!rounded-e-none:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.\!rounded-s-none:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.\!rounded-s-none:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.\!rounded-s-none:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-b-large {
  border-bottom-right-radius: var(--heroui-radius-large);
  border-bottom-left-radius: var(--heroui-radius-large);
}

.rounded-b-medium {
  border-bottom-right-radius: var(--heroui-radius-medium);
  border-bottom-left-radius: var(--heroui-radius-medium);
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-b-small {
  border-bottom-right-radius: var(--heroui-radius-small);
  border-bottom-left-radius: var(--heroui-radius-small);
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-t-large {
  border-top-left-radius: var(--heroui-radius-large);
  border-top-right-radius: var(--heroui-radius-large);
}

.rounded-t-medium {
  border-top-left-radius: var(--heroui-radius-medium);
  border-top-right-radius: var(--heroui-radius-medium);
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-t-small {
  border-top-left-radius: var(--heroui-radius-small);
  border-top-right-radius: var(--heroui-radius-small);
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-medium {
  border-width: var(--heroui-border-width-medium);
}

.border-small {
  border-width: var(--heroui-border-width-small);
}

.border-x-\[calc\(theme\(spacing\.5\)\/2\)\] {
  border-left-width: .625rem;
  border-right-width: .625rem;
}

.border-x-\[calc\(theme\(spacing\.6\)\/2\)\] {
  border-left-width: .75rem;
  border-right-width: .75rem;
}

.border-x-\[calc\(theme\(spacing\.7\)\/2\)\] {
  border-left-width: .875rem;
  border-right-width: .875rem;
}

.border-y-\[calc\(theme\(spacing\.5\)\/2\)\] {
  border-top-width: .625rem;
  border-bottom-width: .625rem;
}

.border-y-\[calc\(theme\(spacing\.6\)\/2\)\] {
  border-top-width: .75rem;
  border-bottom-width: .75rem;
}

.border-y-\[calc\(theme\(spacing\.7\)\/2\)\] {
  border-top-width: .875rem;
  border-bottom-width: .875rem;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-medium {
  border-bottom-width: var(--heroui-border-width-medium);
}

.border-solid {
  border-style: solid;
}

.border-dotted {
  border-style: dotted;
}

.\!border-none {
  border-style: none !important;
}

.border-none {
  border-style: none;
}

.\!border-danger {
  --tw-border-opacity: 1 !important;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity))) !important;
}

.border-background {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-background) / var(--heroui-background-opacity, var(--tw-border-opacity)));
}

.border-danger {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.border-danger-100 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger-100) / var(--heroui-danger-100-opacity, var(--tw-border-opacity)));
}

.border-danger-200 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger-200) / var(--heroui-danger-200-opacity, var(--tw-border-opacity)));
}

.border-danger-400 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger-400) / var(--heroui-danger-400-opacity, var(--tw-border-opacity)));
}

.border-default {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-border-opacity)));
}

.border-default-100 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-border-opacity)));
}

.border-default-200 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-200) / var(--heroui-default-200-opacity, var(--tw-border-opacity)));
}

.border-default-300 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-300) / var(--heroui-default-300-opacity, var(--tw-border-opacity)));
}

.border-default-400 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-400) / var(--heroui-default-400-opacity, var(--tw-border-opacity)));
}

.border-divider {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-divider) / var(--heroui-divider-opacity, var(--tw-border-opacity)));
}

.border-foreground {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-border-opacity)));
}

.border-foreground-400 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-foreground-400) / var(--heroui-foreground-400-opacity, var(--tw-border-opacity)));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.border-primary-100 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary-100) / var(--heroui-primary-100-opacity, var(--tw-border-opacity)));
}

.border-primary-200 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary-200) / var(--heroui-primary-200-opacity, var(--tw-border-opacity)));
}

.border-primary-400 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary-400) / var(--heroui-primary-400-opacity, var(--tw-border-opacity)));
}

.border-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.border-secondary-100 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary-100) / var(--heroui-secondary-100-opacity, var(--tw-border-opacity)));
}

.border-secondary-200 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary-200) / var(--heroui-secondary-200-opacity, var(--tw-border-opacity)));
}

.border-secondary-400 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary-400) / var(--heroui-secondary-400-opacity, var(--tw-border-opacity)));
}

.border-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.border-success-100 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success-100) / var(--heroui-success-100-opacity, var(--tw-border-opacity)));
}

.border-success-200 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success-200) / var(--heroui-success-200-opacity, var(--tw-border-opacity)));
}

.border-success-300 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success-300) / var(--heroui-success-300-opacity, var(--tw-border-opacity)));
}

.border-success-400 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success-400) / var(--heroui-success-400-opacity, var(--tw-border-opacity)));
}

.border-transparent {
  border-color: #0000;
}

.border-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.border-warning-100 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning-100) / var(--heroui-warning-100-opacity, var(--tw-border-opacity)));
}

.border-warning-200 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning-200) / var(--heroui-warning-200-opacity, var(--tw-border-opacity)));
}

.border-warning-300 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning-300) / var(--heroui-warning-300-opacity, var(--tw-border-opacity)));
}

.border-warning-400 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning-400) / var(--heroui-warning-400-opacity, var(--tw-border-opacity)));
}

.border-x-transparent {
  border-left-color: #0000;
  border-right-color: #0000;
}

.border-y-transparent {
  border-top-color: #0000;
  border-bottom-color: #0000;
}

.border-b-current {
  border-bottom-color: currentColor;
}

.border-b-danger {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.border-b-default {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-border-opacity)));
}

.border-b-foreground {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-border-opacity)));
}

.border-b-primary {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.border-b-secondary {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.border-b-success {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.border-b-warning {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.border-b-white {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(255, 255, 255, var(--tw-border-opacity, 1));
}

.border-l-transparent {
  border-left-color: #0000;
}

.border-r-transparent {
  border-right-color: #0000;
}

.border-s-danger {
  --tw-border-opacity: 1;
}

.border-s-danger:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-left-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.border-s-danger:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.border-s-danger:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.border-s-foreground {
  --tw-border-opacity: 1;
}

.border-s-foreground:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-left-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-border-opacity)));
}

.border-s-foreground:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-border-opacity)));
}

.border-s-foreground:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-border-opacity)));
}

.border-s-primary {
  --tw-border-opacity: 1;
}

.border-s-primary:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-left-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.border-s-primary:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.border-s-primary:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.border-s-secondary {
  --tw-border-opacity: 1;
}

.border-s-secondary:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-left-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.border-s-secondary:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.border-s-secondary:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.border-s-success {
  --tw-border-opacity: 1;
}

.border-s-success:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-left-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.border-s-success:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.border-s-success:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.border-s-warning {
  --tw-border-opacity: 1;
}

.border-s-warning:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-left-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.border-s-warning:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.border-s-warning:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-right-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.border-t-transparent {
  border-top-color: #0000;
}

.\!bg-danger-50 {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity))) !important;
}

.\!bg-transparent {
  background-color: #0000 !important;
}

.bg-background {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-background) / var(--heroui-background-opacity, var(--tw-bg-opacity)));
}

.bg-background\/10 {
  background-color: hsl(var(--heroui-background) / .1);
}

.bg-background\/70 {
  background-color: hsl(var(--heroui-background) / .7);
}

.bg-background\/80 {
  background-color: hsl(var(--heroui-background) / .8);
}

.bg-content1 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-content1) / var(--heroui-content1-opacity, var(--tw-bg-opacity)));
}

.bg-content3 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-content3) / var(--heroui-content3-opacity, var(--tw-bg-opacity)));
}

.bg-current {
  background-color: currentColor;
}

.bg-danger {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.bg-danger-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-100) / var(--heroui-danger-100-opacity, var(--tw-bg-opacity)));
}

.bg-danger-400 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-400) / var(--heroui-danger-400-opacity, var(--tw-bg-opacity)));
}

.bg-danger-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity)));
}

.bg-danger\/20 {
  background-color: hsl(var(--heroui-danger) / .2);
}

.bg-default {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-bg-opacity)));
}

.bg-default-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-bg-opacity)));
}

.bg-default-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-200) / var(--heroui-default-200-opacity, var(--tw-bg-opacity)));
}

.bg-default-300\/50 {
  background-color: hsl(var(--heroui-default-300) / .5);
}

.bg-default-400 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-400) / var(--heroui-default-400-opacity, var(--tw-bg-opacity)));
}

.bg-default-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-50) / var(--heroui-default-50-opacity, var(--tw-bg-opacity)));
}

.bg-default-500 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-500) / var(--heroui-default-500-opacity, var(--tw-bg-opacity)));
}

.bg-default-800 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-800) / var(--heroui-default-800-opacity, var(--tw-bg-opacity)));
}

.bg-default\/40 {
  background-color: hsl(var(--heroui-default) / .4);
}

.bg-divider {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-divider) / var(--heroui-divider-opacity, var(--tw-bg-opacity)));
}

.bg-foreground {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-bg-opacity)));
}

.bg-foreground-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-foreground-100) / var(--heroui-foreground-100-opacity, var(--tw-bg-opacity)));
}

.bg-foreground-400 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-foreground-400) / var(--heroui-foreground-400-opacity, var(--tw-bg-opacity)));
}

.bg-foreground\/10 {
  background-color: hsl(var(--heroui-foreground) / .1);
}

.bg-overlay\/30 {
  background-color: hsl(var(--heroui-overlay) / .3);
}

.bg-overlay\/50 {
  background-color: hsl(var(--heroui-overlay) / .5);
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-100) / var(--heroui-primary-100-opacity, var(--tw-bg-opacity)));
}

.bg-primary-400 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-400) / var(--heroui-primary-400-opacity, var(--tw-bg-opacity)));
}

.bg-primary-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-50) / var(--heroui-primary-50-opacity, var(--tw-bg-opacity)));
}

.bg-primary\/20 {
  background-color: hsl(var(--heroui-primary) / .2);
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.bg-secondary-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-100) / var(--heroui-secondary-100-opacity, var(--tw-bg-opacity)));
}

.bg-secondary-400 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-400) / var(--heroui-secondary-400-opacity, var(--tw-bg-opacity)));
}

.bg-secondary-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-50) / var(--heroui-secondary-50-opacity, var(--tw-bg-opacity)));
}

.bg-secondary\/20 {
  background-color: hsl(var(--heroui-secondary) / .2);
}

.bg-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.bg-success-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-100) / var(--heroui-success-100-opacity, var(--tw-bg-opacity)));
}

.bg-success-400 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-400) / var(--heroui-success-400-opacity, var(--tw-bg-opacity)));
}

.bg-success-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-50) / var(--heroui-success-50-opacity, var(--tw-bg-opacity)));
}

.bg-success\/20 {
  background-color: hsl(var(--heroui-success) / .2);
}

.bg-transparent {
  background-color: #0000;
}

.bg-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.bg-warning-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-100) / var(--heroui-warning-100-opacity, var(--tw-bg-opacity)));
}

.bg-warning-400 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-400) / var(--heroui-warning-400-opacity, var(--tw-bg-opacity)));
}

.bg-warning-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-50) / var(--heroui-warning-50-opacity, var(--tw-bg-opacity)));
}

.bg-warning\/20 {
  background-color: hsl(var(--heroui-warning) / .2);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity, 1));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-stripe-gradient-danger {
  background-image: linear-gradient(45deg, hsl(var(--heroui-danger-200)) 25%, hsl(var(--heroui-danger)) 25%, hsl(var(--heroui-danger)) 50%, hsl(var(--heroui-danger-200)) 50%, hsl(var(--heroui-danger-200)) 75%, hsl(var(--heroui-danger)) 75%, hsl(var(--heroui-danger)));
}

.bg-stripe-gradient-default {
  background-image: linear-gradient(45deg, hsl(var(--heroui-default-200)) 25%, hsl(var(--heroui-default-400)) 25%, hsl(var(--heroui-default-400)) 50%, hsl(var(--heroui-default-200)) 50%, hsl(var(--heroui-default-200)) 75%, hsl(var(--heroui-default-400)) 75%, hsl(var(--heroui-default-400)));
}

.bg-stripe-gradient-primary {
  background-image: linear-gradient(45deg, hsl(var(--heroui-primary-200)) 25%, hsl(var(--heroui-primary)) 25%, hsl(var(--heroui-primary)) 50%, hsl(var(--heroui-primary-200)) 50%, hsl(var(--heroui-primary-200)) 75%, hsl(var(--heroui-primary)) 75%, hsl(var(--heroui-primary)));
}

.bg-stripe-gradient-secondary {
  background-image: linear-gradient(45deg, hsl(var(--heroui-secondary-200)) 25%, hsl(var(--heroui-secondary)) 25%, hsl(var(--heroui-secondary)) 50%, hsl(var(--heroui-secondary-200)) 50%, hsl(var(--heroui-secondary-200)) 75%, hsl(var(--heroui-secondary)) 75%, hsl(var(--heroui-secondary)));
}

.bg-stripe-gradient-success {
  background-image: linear-gradient(45deg, hsl(var(--heroui-success-200)) 25%, hsl(var(--heroui-success)) 25%, hsl(var(--heroui-success)) 50%, hsl(var(--heroui-success-200)) 50%, hsl(var(--heroui-success-200)) 75%, hsl(var(--heroui-success)) 75%, hsl(var(--heroui-success)));
}

.bg-stripe-gradient-warning {
  background-image: linear-gradient(45deg, hsl(var(--heroui-warning-200)) 25%, hsl(var(--heroui-warning)) 25%, hsl(var(--heroui-warning)) 50%, hsl(var(--heroui-warning-200)) 50%, hsl(var(--heroui-warning-200)) 75%, hsl(var(--heroui-warning)) 75%, hsl(var(--heroui-warning)));
}

.from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-transparent {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.to-current {
  --tw-gradient-to: currentColor var(--tw-gradient-to-position);
}

.to-danger {
  --tw-gradient-to: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, 1)) var(--tw-gradient-to-position);
}

.to-default {
  --tw-gradient-to: hsl(var(--heroui-default) / var(--heroui-default-opacity, 1)) var(--tw-gradient-to-position);
}

.to-primary {
  --tw-gradient-to: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, 1)) var(--tw-gradient-to-position);
}

.to-secondary {
  --tw-gradient-to: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, 1)) var(--tw-gradient-to-position);
}

.to-success {
  --tw-gradient-to: hsl(var(--heroui-success) / var(--heroui-success-opacity, 1)) var(--tw-gradient-to-position);
}

.to-warning {
  --tw-gradient-to: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, 1)) var(--tw-gradient-to-position);
}

.to-white {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.bg-stripe-size {
  background-size: 1.25rem 1.25rem;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-default-300\/50 {
  stroke: hsl(var(--heroui-default-300) / .5);
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.\!px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.\!pb-0 {
  padding-bottom: 0 !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0\.5 {
  padding-bottom: .125rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pe-2:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .5rem;
}

.pe-2:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: .5rem;
}

.pe-2:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: .5rem;
}

.pe-6:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 1.5rem;
}

.pe-6:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: 1.5rem;
}

.pe-6:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: 1.5rem;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-\[1px\] {
  padding-left: 1px;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-1 {
  padding-right: .25rem;
}

.ps-1:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: .25rem;
}

.ps-1:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .25rem;
}

.ps-1:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .25rem;
}

.ps-2:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: .5rem;
}

.ps-2:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .5rem;
}

.ps-2:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-2 {
  padding-top: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.align-middle {
  vertical-align: middle;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-\[0\.55rem\] {
  font-size: .55rem;
}

.text-\[0\.5rem\] {
  font-size: .5rem;
}

.text-\[0\.6rem\] {
  font-size: .6rem;
}

.text-\[100\%\] {
  font-size: 100%;
}

.text-large {
  font-size: var(--heroui-font-size-large);
  line-height: var(--heroui-line-height-large);
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-medium {
  font-size: var(--heroui-font-size-medium);
  line-height: var(--heroui-line-height-medium);
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-small {
  font-size: var(--heroui-font-size-small);
  line-height: var(--heroui-line-height-small);
}

.text-tiny {
  font-size: var(--heroui-font-size-tiny);
  line-height: var(--heroui-line-height-tiny);
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-extralight {
  font-weight: 200;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-\[1\.15\] {
  line-height: 1.15;
}

.leading-\[32px\] {
  line-height: 32px;
}

.\!text-danger {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-text-opacity))) !important;
}

.text-background {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-background) / var(--heroui-background-opacity, var(--tw-text-opacity)));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity, 1));
}

.text-current {
  color: currentColor;
}

.text-danger {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-text-opacity)));
}

.text-danger-300 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-300) / var(--heroui-danger-300-opacity, var(--tw-text-opacity)));
}

.text-danger-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-400) / var(--heroui-danger-400-opacity, var(--tw-text-opacity)));
}

.text-danger-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-500) / var(--heroui-danger-500-opacity, var(--tw-text-opacity)));
}

.text-danger-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-600) / var(--heroui-danger-600-opacity, var(--tw-text-opacity)));
}

.text-danger-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.text-danger\/80 {
  color: hsl(var(--heroui-danger) / .8);
}

.text-default {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-text-opacity)));
}

.text-default-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-400) / var(--heroui-default-400-opacity, var(--tw-text-opacity)));
}

.text-default-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-500) / var(--heroui-default-500-opacity, var(--tw-text-opacity)));
}

.text-default-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-600) / var(--heroui-default-600-opacity, var(--tw-text-opacity)));
}

.text-default-700 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-700) / var(--heroui-default-700-opacity, var(--tw-text-opacity)));
}

.text-default-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-text-opacity)));
}

.text-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-text-opacity)));
}

.text-foreground-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground-400) / var(--heroui-foreground-400-opacity, var(--tw-text-opacity)));
}

.text-foreground-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground-500) / var(--heroui-foreground-500-opacity, var(--tw-text-opacity)));
}

.text-foreground-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground-600) / var(--heroui-foreground-600-opacity, var(--tw-text-opacity)));
}

.text-foreground\/50 {
  color: hsl(var(--heroui-foreground) / .5);
}

.text-inherit {
  color: inherit;
}

.text-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-text-opacity)));
}

.text-primary-300 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-300) / var(--heroui-primary-300-opacity, var(--tw-text-opacity)));
}

.text-primary-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-400) / var(--heroui-primary-400-opacity, var(--tw-text-opacity)));
}

.text-primary-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-500) / var(--heroui-primary-500-opacity, var(--tw-text-opacity)));
}

.text-primary-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-600) / var(--heroui-primary-600-opacity, var(--tw-text-opacity)));
}

.text-primary-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-foreground) / var(--heroui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.text-primary\/80 {
  color: hsl(var(--heroui-primary) / .8);
}

.text-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-text-opacity)));
}

.text-secondary-300 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-300) / var(--heroui-secondary-300-opacity, var(--tw-text-opacity)));
}

.text-secondary-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-400) / var(--heroui-secondary-400-opacity, var(--tw-text-opacity)));
}

.text-secondary-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-500) / var(--heroui-secondary-500-opacity, var(--tw-text-opacity)));
}

.text-secondary-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-600) / var(--heroui-secondary-600-opacity, var(--tw-text-opacity)));
}

.text-secondary-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-foreground) / var(--heroui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.text-secondary\/80 {
  color: hsl(var(--heroui-secondary) / .8);
}

.text-success {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-text-opacity)));
}

.text-success-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-400) / var(--heroui-success-400-opacity, var(--tw-text-opacity)));
}

.text-success-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-500) / var(--heroui-success-500-opacity, var(--tw-text-opacity)));
}

.text-success-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-600) / var(--heroui-success-600-opacity, var(--tw-text-opacity)));
}

.text-success-700 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-700) / var(--heroui-success-700-opacity, var(--tw-text-opacity)));
}

.text-success-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity)));
}

.text-success\/80 {
  color: hsl(var(--heroui-success) / .8);
}

.text-transparent {
  color: #0000;
}

.text-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-text-opacity)));
}

.text-warning-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-400) / var(--heroui-warning-400-opacity, var(--tw-text-opacity)));
}

.text-warning-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-500) / var(--heroui-warning-500-opacity, var(--tw-text-opacity)));
}

.text-warning-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-600) / var(--heroui-warning-600-opacity, var(--tw-text-opacity)));
}

.text-warning-700 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-700) / var(--heroui-warning-700-opacity, var(--tw-text-opacity)));
}

.text-warning-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.text-warning\/80 {
  color: hsl(var(--heroui-warning) / .8);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.no-underline {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-25 {
  opacity: .25;
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-\[0\.0001\] {
  opacity: .0001;
}

.opacity-\[value\] {
  opacity: value;
}

.opacity-disabled {
  opacity: var(--heroui-disabled-opacity);
}

.\!shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_1px_0px_0_rgba\(0\,0\,0\,0\.05\)\] {
  --tw-shadow: 0 1px 0px 0 #0000000d;
  --tw-shadow-colored: 0 1px 0px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_20px_20px_0px_rgb\(0_0_0\/0\.05\)\] {
  --tw-shadow: 0px 20px 20px 0px #0000000d;
  --tw-shadow-colored: 0px 20px 20px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-large {
  --tw-shadow: var(--heroui-box-shadow-large);
  --tw-shadow-colored: var(--heroui-box-shadow-large);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-medium {
  --tw-shadow: var(--heroui-box-shadow-medium);
  --tw-shadow-colored: var(--heroui-box-shadow-medium);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-small {
  --tw-shadow: var(--heroui-box-shadow-small);
  --tw-shadow-colored: var(--heroui-box-shadow-small);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-danger\/40 {
  --tw-shadow-color: hsl(var(--heroui-danger) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-default\/50 {
  --tw-shadow-color: hsl(var(--heroui-default) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-foreground\/40 {
  --tw-shadow-color: hsl(var(--heroui-foreground) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/40 {
  --tw-shadow-color: hsl(var(--heroui-primary) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-secondary\/40 {
  --tw-shadow-color: hsl(var(--heroui-secondary) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-success\/40 {
  --tw-shadow-color: hsl(var(--heroui-success) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-warning\/40 {
  --tw-shadow-color: hsl(var(--heroui-warning) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.\!outline-none {
  outline-offset: 2px !important;
  outline: 2px solid #0000 !important;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-background {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--heroui-background) / var(--heroui-background-opacity, var(--tw-ring-opacity)));
}

.ring-danger {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-ring-opacity)));
}

.ring-default {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-ring-opacity)));
}

.ring-focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--heroui-focus) / var(--heroui-focus-opacity, var(--tw-ring-opacity)));
}

.ring-primary {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-ring-opacity)));
}

.ring-secondary {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-ring-opacity)));
}

.ring-success {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-ring-opacity)));
}

.ring-transparent {
  --tw-ring-color: transparent;
}

.ring-warning {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-ring-opacity)));
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-background {
  --tw-ring-offset-color: hsl(var(--heroui-background) / var(--heroui-background-opacity, 1));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-lg {
  --tw-blur: blur(16px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-150 {
  --tw-saturate: saturate(1.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-disabled {
  --tw-backdrop-opacity: opacity(var(--heroui-disabled-opacity));
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-150 {
  --tw-backdrop-saturate: saturate(1.5);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.\!transition-none {
  transition-property: none !important;
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[color\,opacity\] {
  transition-property: color, opacity;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[opacity\,transform\] {
  transition-property: opacity, transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[transform\,background-color\,color\] {
  transition-property: transform, background-color, color;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[transform\,color\,left\,opacity\] {
  transition-property: transform, color, left, opacity;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-none {
  transition-property: none;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\!duration-100 {
  transition-duration: .1s !important;
}

.\!duration-150 {
  transition-duration: .15s !important;
}

.\!duration-200 {
  transition-duration: .2s !important;
}

.\!duration-250 {
  transition-duration: .25s !important;
}

.\!duration-300 {
  transition-duration: .3s !important;
}

.\!duration-500 {
  transition-duration: .5s !important;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-300 {
  transition-duration: .3s;
}

.\!ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
}

.\!ease-soft-spring {
  transition-timing-function: cubic-bezier(.155, 1.105, .295, 1.12) !important;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.will-change-auto {
  will-change: auto;
}

.will-change-transform {
  will-change: transform;
}

:root, .light, [data-theme="light"] {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  --heroui-background: 0 0% 100%;
  --heroui-foreground-50: 0 0% 98.04%;
  --heroui-foreground-100: 240 4.76% 95.88%;
  --heroui-foreground-200: 240 5.88% 90%;
  --heroui-foreground-300: 240 4.88% 83.92%;
  --heroui-foreground-400: 240 5.03% 64.9%;
  --heroui-foreground-500: 240 3.83% 46.08%;
  --heroui-foreground-600: 240 5.2% 33.92%;
  --heroui-foreground-700: 240 5.26% 26.08%;
  --heroui-foreground-800: 240 3.7% 15.88%;
  --heroui-foreground-900: 240 5.88% 10%;
  --heroui-foreground: 201.82 24.44% 8.82%;
  --heroui-divider: 0 0% 6.67%;
  --heroui-divider-opacity: .15;
  --heroui-focus: 212.02 100% 46.67%;
  --heroui-overlay: 0 0% 0%;
  --heroui-content1: 0 0% 100%;
  --heroui-content1-foreground: 201.82 24.44% 8.82%;
  --heroui-content2: 240 4.76% 95.88%;
  --heroui-content2-foreground: 240 3.7% 15.88%;
  --heroui-content3: 240 5.88% 90%;
  --heroui-content3-foreground: 240 5.26% 26.08%;
  --heroui-content4: 240 4.88% 83.92%;
  --heroui-content4-foreground: 240 5.2% 33.92%;
  --heroui-default-50: 0 0% 98.04%;
  --heroui-default-100: 240 4.76% 95.88%;
  --heroui-default-200: 240 5.88% 90%;
  --heroui-default-300: 240 4.88% 83.92%;
  --heroui-default-400: 240 5.03% 64.9%;
  --heroui-default-500: 240 3.83% 46.08%;
  --heroui-default-600: 240 5.2% 33.92%;
  --heroui-default-700: 240 5.26% 26.08%;
  --heroui-default-800: 240 3.7% 15.88%;
  --heroui-default-900: 240 5.88% 10%;
  --heroui-default-foreground: 0 0% 0%;
  --heroui-default: 240 4.88% 83.92%;
  --heroui-primary-50: 212.5 92.31% 94.9%;
  --heroui-primary-100: 211.84 92.45% 89.61%;
  --heroui-primary-200: 211.84 92.45% 79.22%;
  --heroui-primary-300: 212.24 92.45% 68.82%;
  --heroui-primary-400: 212.14 92.45% 58.43%;
  --heroui-primary-500: 212.02 100% 46.67%;
  --heroui-primary-600: 212.14 100% 38.43%;
  --heroui-primary-700: 212.24 100% 28.82%;
  --heroui-primary-800: 211.84 100% 19.22%;
  --heroui-primary-900: 211.84 100% 9.61%;
  --heroui-primary-foreground: 0 0% 100%;
  --heroui-primary: 212.02 100% 46.67%;
  --heroui-secondary-50: 270 61.54% 94.9%;
  --heroui-secondary-100: 270 59.26% 89.41%;
  --heroui-secondary-200: 270 59.26% 78.82%;
  --heroui-secondary-300: 270 59.26% 68.24%;
  --heroui-secondary-400: 270 59.26% 57.65%;
  --heroui-secondary-500: 270 66.67% 47.06%;
  --heroui-secondary-600: 270 66.67% 37.65%;
  --heroui-secondary-700: 270 66.67% 28.24%;
  --heroui-secondary-800: 270 66.67% 18.82%;
  --heroui-secondary-900: 270 66.67% 9.41%;
  --heroui-secondary-foreground: 0 0% 100%;
  --heroui-secondary: 270 66.67% 47.06%;
  --heroui-success-50: 146.67 64.29% 94.51%;
  --heroui-success-100: 145.71 61.4% 88.82%;
  --heroui-success-200: 146.2 61.74% 77.45%;
  --heroui-success-300: 145.79 62.57% 66.47%;
  --heroui-success-400: 146.01 62.45% 55.1%;
  --heroui-success-500: 145.96 79.46% 43.92%;
  --heroui-success-600: 146.01 79.89% 35.1%;
  --heroui-success-700: 145.79 79.26% 26.47%;
  --heroui-success-800: 146.2 79.78% 17.45%;
  --heroui-success-900: 145.71 77.78% 8.82%;
  --heroui-success-foreground: 0 0% 0%;
  --heroui-success: 145.96 79.46% 43.92%;
  --heroui-warning-50: 54.55 91.67% 95.29%;
  --heroui-warning-100: 37.14 91.3% 90.98%;
  --heroui-warning-200: 37.14 91.3% 81.96%;
  --heroui-warning-300: 36.96 91.24% 73.14%;
  --heroui-warning-400: 37.01 91.26% 64.12%;
  --heroui-warning-500: 37.03 91.27% 55.1%;
  --heroui-warning-600: 37.01 74.22% 44.12%;
  --heroui-warning-700: 36.96 73.96% 33.14%;
  --heroui-warning-800: 37.14 75% 21.96%;
  --heroui-warning-900: 37.14 75% 10.98%;
  --heroui-warning-foreground: 0 0% 0%;
  --heroui-warning: 37.03 91.27% 55.1%;
  --heroui-danger-50: 339.13 92% 95.1%;
  --heroui-danger-100: 340 91.84% 90.39%;
  --heroui-danger-200: 339.33 90% 80.39%;
  --heroui-danger-300: 339.11 90.6% 70.78%;
  --heroui-danger-400: 339 90% 60.78%;
  --heroui-danger-500: 339.2 90.36% 51.18%;
  --heroui-danger-600: 339 86.54% 40.78%;
  --heroui-danger-700: 339.11 85.99% 30.78%;
  --heroui-danger-800: 339.33 86.54% 20.39%;
  --heroui-danger-900: 340 84.91% 10.39%;
  --heroui-danger-foreground: 0 0% 100%;
  --heroui-danger: 339.2 90.36% 51.18%;
  --heroui-divider-weight: 1px;
  --heroui-disabled-opacity: .5;
  --heroui-font-size-tiny: .75rem;
  --heroui-font-size-small: .875rem;
  --heroui-font-size-medium: 1rem;
  --heroui-font-size-large: 1.125rem;
  --heroui-line-height-tiny: 1rem;
  --heroui-line-height-small: 1.25rem;
  --heroui-line-height-medium: 1.5rem;
  --heroui-line-height-large: 1.75rem;
  --heroui-radius-small: 8px;
  --heroui-radius-medium: 12px;
  --heroui-radius-large: 14px;
  --heroui-border-width-small: 1px;
  --heroui-border-width-medium: 2px;
  --heroui-border-width-large: 3px;
  --heroui-box-shadow-small: 0px 0px 5px 0px #00000005, 0px 2px 10px 0px #0000000f, 0px 0px 1px 0px #0000004d;
  --heroui-box-shadow-medium: 0px 0px 15px 0px #00000008, 0px 2px 30px 0px #00000014, 0px 0px 1px 0px #0000004d;
  --heroui-box-shadow-large: 0px 0px 30px 0px #0000000a, 0px 30px 60px 0px #0000001f, 0px 0px 1px 0px #0000004d;
  --heroui-hover-opacity: .8;
}

.dark, [data-theme="dark"] {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  --heroui-background: 0 0% 0%;
  --heroui-foreground-50: 240 5.88% 10%;
  --heroui-foreground-100: 240 3.7% 15.88%;
  --heroui-foreground-200: 240 5.26% 26.08%;
  --heroui-foreground-300: 240 5.2% 33.92%;
  --heroui-foreground-400: 240 3.83% 46.08%;
  --heroui-foreground-500: 240 5.03% 64.9%;
  --heroui-foreground-600: 240 4.88% 83.92%;
  --heroui-foreground-700: 240 5.88% 90%;
  --heroui-foreground-800: 240 4.76% 95.88%;
  --heroui-foreground-900: 0 0% 98.04%;
  --heroui-foreground: 210 5.56% 92.94%;
  --heroui-focus: 212.02 100% 46.67%;
  --heroui-overlay: 0 0% 0%;
  --heroui-divider: 0 0% 100%;
  --heroui-divider-opacity: .15;
  --heroui-content1: 240 5.88% 10%;
  --heroui-content1-foreground: 0 0% 98.04%;
  --heroui-content2: 240 3.7% 15.88%;
  --heroui-content2-foreground: 240 4.76% 95.88%;
  --heroui-content3: 240 5.26% 26.08%;
  --heroui-content3-foreground: 240 5.88% 90%;
  --heroui-content4: 240 5.2% 33.92%;
  --heroui-content4-foreground: 240 4.88% 83.92%;
  --heroui-default-50: 240 5.88% 10%;
  --heroui-default-100: 240 3.7% 15.88%;
  --heroui-default-200: 240 5.26% 26.08%;
  --heroui-default-300: 240 5.2% 33.92%;
  --heroui-default-400: 240 3.83% 46.08%;
  --heroui-default-500: 240 5.03% 64.9%;
  --heroui-default-600: 240 4.88% 83.92%;
  --heroui-default-700: 240 5.88% 90%;
  --heroui-default-800: 240 4.76% 95.88%;
  --heroui-default-900: 0 0% 98.04%;
  --heroui-default-foreground: 0 0% 100%;
  --heroui-default: 240 5.26% 26.08%;
  --heroui-primary-50: 211.84 100% 9.61%;
  --heroui-primary-100: 211.84 100% 19.22%;
  --heroui-primary-200: 212.24 100% 28.82%;
  --heroui-primary-300: 212.14 100% 38.43%;
  --heroui-primary-400: 212.02 100% 46.67%;
  --heroui-primary-500: 212.14 92.45% 58.43%;
  --heroui-primary-600: 212.24 92.45% 68.82%;
  --heroui-primary-700: 211.84 92.45% 79.22%;
  --heroui-primary-800: 211.84 92.45% 89.61%;
  --heroui-primary-900: 212.5 92.31% 94.9%;
  --heroui-primary-foreground: 0 0% 100%;
  --heroui-primary: 212.02 100% 46.67%;
  --heroui-secondary-50: 270 66.67% 9.41%;
  --heroui-secondary-100: 270 66.67% 18.82%;
  --heroui-secondary-200: 270 66.67% 28.24%;
  --heroui-secondary-300: 270 66.67% 37.65%;
  --heroui-secondary-400: 270 66.67% 47.06%;
  --heroui-secondary-500: 270 59.26% 57.65%;
  --heroui-secondary-600: 270 59.26% 68.24%;
  --heroui-secondary-700: 270 59.26% 78.82%;
  --heroui-secondary-800: 270 59.26% 89.41%;
  --heroui-secondary-900: 270 61.54% 94.9%;
  --heroui-secondary-foreground: 0 0% 100%;
  --heroui-secondary: 270 59.26% 57.65%;
  --heroui-success-50: 145.71 77.78% 8.82%;
  --heroui-success-100: 146.2 79.78% 17.45%;
  --heroui-success-200: 145.79 79.26% 26.47%;
  --heroui-success-300: 146.01 79.89% 35.1%;
  --heroui-success-400: 145.96 79.46% 43.92%;
  --heroui-success-500: 146.01 62.45% 55.1%;
  --heroui-success-600: 145.79 62.57% 66.47%;
  --heroui-success-700: 146.2 61.74% 77.45%;
  --heroui-success-800: 145.71 61.4% 88.82%;
  --heroui-success-900: 146.67 64.29% 94.51%;
  --heroui-success-foreground: 0 0% 0%;
  --heroui-success: 145.96 79.46% 43.92%;
  --heroui-warning-50: 37.14 75% 10.98%;
  --heroui-warning-100: 37.14 75% 21.96%;
  --heroui-warning-200: 36.96 73.96% 33.14%;
  --heroui-warning-300: 37.01 74.22% 44.12%;
  --heroui-warning-400: 37.03 91.27% 55.1%;
  --heroui-warning-500: 37.01 91.26% 64.12%;
  --heroui-warning-600: 36.96 91.24% 73.14%;
  --heroui-warning-700: 37.14 91.3% 81.96%;
  --heroui-warning-800: 37.14 91.3% 90.98%;
  --heroui-warning-900: 54.55 91.67% 95.29%;
  --heroui-warning-foreground: 0 0% 0%;
  --heroui-warning: 37.03 91.27% 55.1%;
  --heroui-danger-50: 340 84.91% 10.39%;
  --heroui-danger-100: 339.33 86.54% 20.39%;
  --heroui-danger-200: 339.11 85.99% 30.78%;
  --heroui-danger-300: 339 86.54% 40.78%;
  --heroui-danger-400: 339.2 90.36% 51.18%;
  --heroui-danger-500: 339 90% 60.78%;
  --heroui-danger-600: 339.11 90.6% 70.78%;
  --heroui-danger-700: 339.33 90% 80.39%;
  --heroui-danger-800: 340 91.84% 90.39%;
  --heroui-danger-900: 339.13 92% 95.1%;
  --heroui-danger-foreground: 0 0% 100%;
  --heroui-danger: 339.2 90.36% 51.18%;
  --heroui-divider-weight: 1px;
  --heroui-disabled-opacity: .5;
  --heroui-font-size-tiny: .75rem;
  --heroui-font-size-small: .875rem;
  --heroui-font-size-medium: 1rem;
  --heroui-font-size-large: 1.125rem;
  --heroui-line-height-tiny: 1rem;
  --heroui-line-height-small: 1.25rem;
  --heroui-line-height-medium: 1.5rem;
  --heroui-line-height-large: 1.75rem;
  --heroui-radius-small: 8px;
  --heroui-radius-medium: 12px;
  --heroui-radius-large: 14px;
  --heroui-border-width-small: 1px;
  --heroui-border-width-medium: 2px;
  --heroui-border-width-large: 3px;
  --heroui-box-shadow-small: 0px 0px 5px 0px #0000000d, 0px 2px 10px 0px #0003, inset 0px 0px 1px 0px #ffffff26;
  --heroui-box-shadow-medium: 0px 0px 15px 0px #0000000f, 0px 2px 30px 0px #00000038, inset 0px 0px 1px 0px #ffffff26;
  --heroui-box-shadow-large: 0px 0px 30px 0px #00000012, 0px 30px 60px 0px #00000042, inset 0px 0px 1px 0px #ffffff26;
  --heroui-hover-opacity: .9;
}

.leading-inherit {
  line-height: inherit;
}

.bg-img-inherit {
  background-image: inherit;
}

.bg-clip-inherit {
  background-clip: inherit;
}

.text-fill-inherit {
  -webkit-text-fill-color: inherit;
}

.tap-highlight-transparent {
  -webkit-tap-highlight-color: transparent;
}

.input-search-cancel-button-none::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.transition-background {
  transition-property: background;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-colors-opacity {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-width {
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-height {
  transition-property: height;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-size {
  transition-property: width, height;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-left {
  transition-property: left;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-transform-opacity {
  transition-property: transform, opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-transform-background {
  transition-property: transform, background;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-transform-colors {
  transition-property: transform, color, background, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-transform-colors-opacity {
  transition-property: transform, color, background, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-default {
  -ms-overflow-style: auto;
  scrollbar-width: auto;
}

.scrollbar-default::-webkit-scrollbar {
  display: block;
}

.spinner-bar-animation {
  animation-delay: calc(-1.2s + (.1s * var(--bar-index)));
  transform: rotate(calc(30deg * var(--bar-index))) translate(140%);
}

.spinner-dot-animation {
  animation-delay: calc(.25s * var(--dot-index));
}

.spinner-dot-blink-animation {
  animation-delay: calc(.2s * var(--dot-index));
}

.\[--picker-height\:224px\] {
  --picker-height: 224px;
}

.\[--scale-enter\:100\%\] {
  --scale-enter: 100%;
}

.\[--scale-exit\:100\%\] {
  --scale-exit: 100%;
}

.\[--scroll-shadow-size\:100px\] {
  --scroll-shadow-size: 100px;
}

.\[--slide-enter\:0px\] {
  --slide-enter: 0px;
}

.\[--slide-exit\:80px\] {
  --slide-exit: 80px;
}

.\[-webkit-mask\:radial-gradient\(closest-side\,rgba\(0\,0\,0\,0\.0\)calc\(100\%-3px\)\,rgba\(0\,0\,0\,1\)calc\(100\%-3px\)\)\] {
  -webkit-mask: radial-gradient(closest-side, #0000 calc(100% - 3px), #000 calc(100% - 3px));
}

.\[animation-duration\:1s\] {
  animation-duration: 1s;
}

.\[mask-image\:linear-gradient\(\#000\,\#000\,transparent_0\,\#000_var\(--scroll-shadow-size\)\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\] {
  -webkit-mask-image: linear-gradient(#000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
  -webkit-mask-image: linear-gradient(#000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(#000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #0000;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

* {
  scrollbar-width: none;
}

.screen-with-group-content {
  background: #fff;
  border-radius: 4px;
  flex-direction: column;
  width: 500px;
  display: flex;
  box-shadow: 0 3px 3.84px 2.16px #0c0c0c2b;
}

.screen-with-group-content > p {
  padding: 30px 15px;
}

.ReactModal__Overlay {
  z-index: 9999999;
  background-color: #0000004d !important;
}

.ReactModal__Content {
  max-width: 538px;
  border: none !important;
  border-radius: 14px !important;
  width: calc(100% - 40px) !important;
}

#question-text {
  font-size: 178px;
}

.file\:cursor-pointer::-webkit-file-upload-button {
  cursor: pointer;
}

.file\:cursor-pointer::file-selector-button {
  cursor: pointer;
}

.file\:border-0::-webkit-file-upload-button {
  border-width: 0;
}

.file\:border-0::file-selector-button {
  border-width: 0;
}

.file\:bg-transparent::-webkit-file-upload-button {
  background-color: #0000;
}

.file\:bg-transparent::file-selector-button {
  background-color: #0000;
}

.placeholder\:text-danger::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-foreground-500::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground-500) / var(--heroui-foreground-500-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-primary::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-secondary::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-success-600::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-600) / var(--heroui-success-600-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-warning-600::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-600) / var(--heroui-warning-600-opacity, var(--tw-text-opacity)));
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:inset-0:before {
  content: var(--tw-content);
  inset: 0;
}

.before\:z-0:before {
  content: var(--tw-content);
  z-index: 0;
}

.before\:z-\[-1\]:before {
  content: var(--tw-content);
  z-index: -1;
}

.before\:box-border:before {
  content: var(--tw-content);
  box-sizing: border-box;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:hidden:before {
  content: var(--tw-content);
  display: none;
}

.before\:h-0\.5:before {
  content: var(--tw-content);
  height: .125rem;
}

.before\:h-11:before {
  content: var(--tw-content);
  height: 2.75rem;
}

.before\:h-2\.5:before {
  content: var(--tw-content);
  height: .625rem;
}

.before\:h-4:before {
  content: var(--tw-content);
  height: 1rem;
}

.before\:h-6:before {
  content: var(--tw-content);
  height: 1.5rem;
}

.before\:h-8:before {
  content: var(--tw-content);
  height: 2rem;
}

.before\:h-px:before {
  content: var(--tw-content);
  height: 1px;
}

.before\:w-0:before {
  content: var(--tw-content);
  width: 0;
}

.before\:w-11:before {
  content: var(--tw-content);
  width: 2.75rem;
}

.before\:w-2\.5:before {
  content: var(--tw-content);
  width: .625rem;
}

.before\:w-6:before {
  content: var(--tw-content);
  width: 1.5rem;
}

.before\:w-8:before {
  content: var(--tw-content);
  width: 2rem;
}

.before\:-translate-x-full:before {
  content: var(--tw-content);
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-1:before {
  content: var(--tw-content);
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rotate-0:before {
  content: var(--tw-content);
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rotate-45:before {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes shimmer {
  100% {
    content: var(--tw-content);
    transform: translateX(100%);
  }
}

.before\:animate-\[shimmer_2s_infinite\]:before {
  content: var(--tw-content);
  animation: 2s infinite shimmer;
}

.before\:animate-none:before {
  content: var(--tw-content);
  animation: none;
}

.before\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.5\)\]:before {
  content: var(--tw-content);
  border-radius: calc(var(--heroui-radius-medium) * .5);
}

.before\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.6\)\]:before {
  content: var(--tw-content);
  border-radius: calc(var(--heroui-radius-medium) * .6);
}

.before\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.7\)\]:before {
  content: var(--tw-content);
  border-radius: calc(var(--heroui-radius-medium) * .7);
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:rounded-none:before {
  content: var(--tw-content);
  border-radius: 0;
}

.before\:rounded-sm:before {
  content: var(--tw-content);
  border-radius: .125rem;
}

.before\:border-2:before {
  content: var(--tw-content);
  border-width: 2px;
}

.before\:border-t:before {
  content: var(--tw-content);
  border-top-width: 1px;
}

.before\:border-solid:before {
  content: var(--tw-content);
  border-style: solid;
}

.before\:border-content4\/30:before {
  content: var(--tw-content);
  border-color: hsl(var(--heroui-content4) / .3);
}

.before\:border-danger:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.before\:border-default:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-border-opacity)));
}

.before\:bg-content1:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-content1) / var(--heroui-content1-opacity, var(--tw-bg-opacity)));
}

.before\:bg-current:before {
  content: var(--tw-content);
  background-color: currentColor;
}

.before\:bg-danger:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.before\:bg-danger\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-danger) / .2);
}

.before\:bg-default\/60:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-default) / .6);
}

.before\:bg-foreground:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-bg-opacity)));
}

.before\:bg-primary:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.before\:bg-primary\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-primary) / .2);
}

.before\:bg-secondary:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.before\:bg-secondary\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-secondary) / .2);
}

.before\:bg-success:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.before\:bg-success\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-success) / .2);
}

.before\:bg-warning:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.before\:bg-warning\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-warning) / .2);
}

.before\:bg-gradient-to-r:before {
  content: var(--tw-content);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.before\:from-transparent:before {
  content: var(--tw-content);
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:via-content4:before {
  content: var(--tw-content);
  --tw-gradient-to: hsl(var(--heroui-content4) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--heroui-content4) / var(--heroui-content4-opacity, 1)) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.before\:to-transparent:before {
  content: var(--tw-content);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.before\:opacity-0:before {
  content: var(--tw-content);
  opacity: 0;
}

.before\:opacity-100:before {
  content: var(--tw-content);
  opacity: 1;
}

.before\:shadow-small:before {
  content: var(--tw-content);
  --tw-shadow: var(--heroui-box-shadow-small);
  --tw-shadow-colored: var(--heroui-box-shadow-small);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.before\:transition-colors:before {
  content: var(--tw-content);
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:transition-none:before {
  content: var(--tw-content);
  transition-property: none;
}

.before\:transition-transform:before {
  content: var(--tw-content);
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:duration-150:before {
  content: var(--tw-content);
  transition-duration: .15s;
}

.before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:transition-width:before {
  content: var(--tw-content);
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:inset-0:after {
  content: var(--tw-content);
  inset: 0;
}

.after\:-bottom-1:after {
  content: var(--tw-content);
  bottom: -.25rem;
}

.after\:-bottom-\[2px\]:after {
  content: var(--tw-content);
  bottom: -2px;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:left-0:after {
  content: var(--tw-content);
  left: 0;
}

.after\:left-1\/2:after {
  content: var(--tw-content);
  left: 50%;
}

.after\:right-0:after {
  content: var(--tw-content);
  right: 0;
}

.after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.after\:-z-10:after {
  content: var(--tw-content);
  z-index: -10;
}

.after\:z-0:after {
  content: var(--tw-content);
  z-index: 0;
}

.after\:ml-0\.5:after {
  content: var(--tw-content);
  margin-left: .125rem;
}

.after\:ms-0\.5:after {
  content: var(--tw-content);
}

.after\:ms-0\.5:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
  margin-left: .125rem;
}

.after\:ms-0\.5:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
  margin-right: .125rem;
}

.after\:ms-0\.5:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
  margin-right: .125rem;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-0:after {
  content: var(--tw-content);
  height: 0;
}

.after\:h-4:after {
  content: var(--tw-content);
  height: 1rem;
}

.after\:h-5:after {
  content: var(--tw-content);
  height: 1.25rem;
}

.after\:h-\[2px\]:after {
  content: var(--tw-content);
  height: 2px;
}

.after\:h-divider:after {
  content: var(--tw-content);
  height: var(--heroui-divider-weight);
}

.after\:h-full:after {
  content: var(--tw-content);
  height: 100%;
}

.after\:h-px:after {
  content: var(--tw-content);
  height: 1px;
}

.after\:w-0:after {
  content: var(--tw-content);
  width: 0;
}

.after\:w-4:after {
  content: var(--tw-content);
  width: 1rem;
}

.after\:w-5:after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:w-6:after {
  content: var(--tw-content);
  width: 1.5rem;
}

.after\:w-\[80\%\]:after {
  content: var(--tw-content);
  width: 80%;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:origin-center:after {
  content: var(--tw-content);
  transform-origin: center;
}

.after\:-translate-x-1\/2:after {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:translate-y-1:after {
  content: var(--tw-content);
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rotate-0:after {
  content: var(--tw-content);
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:scale-50:after {
  content: var(--tw-content);
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rounded-\[calc\(theme\(borderRadius\.large\)\/2\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--heroui-radius-large) / 2);
}

.after\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.5\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--heroui-radius-medium) * .5);
}

.after\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.6\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--heroui-radius-medium) * .6);
}

.after\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.7\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--heroui-radius-medium) * .7);
}

.after\:rounded-\[calc\(theme\(borderRadius\.medium\)\/3\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--heroui-radius-medium) / 3);
}

.after\:rounded-\[calc\(theme\(borderRadius\.small\)\/3\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--heroui-radius-small) / 3);
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:rounded-none:after {
  content: var(--tw-content);
  border-radius: 0;
}

.after\:rounded-xl:after {
  content: var(--tw-content);
  border-radius: .75rem;
}

.after\:\!bg-danger:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity))) !important;
}

.after\:bg-background:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-background) / var(--heroui-background-opacity, var(--tw-bg-opacity)));
}

.after\:bg-content1:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-content1) / var(--heroui-content1-opacity, var(--tw-bg-opacity)));
}

.after\:bg-content3:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-content3) / var(--heroui-content3-opacity, var(--tw-bg-opacity)));
}

.after\:bg-current:after {
  content: var(--tw-content);
  background-color: currentColor;
}

.after\:bg-danger:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.after\:bg-default:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-bg-opacity)));
}

.after\:bg-default-foreground:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-bg-opacity)));
}

.after\:bg-divider:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-divider) / var(--heroui-divider-opacity, var(--tw-bg-opacity)));
}

.after\:bg-foreground:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-bg-opacity)));
}

.after\:bg-primary:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.after\:bg-secondary:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.after\:bg-success:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.after\:bg-warning:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.after\:text-danger:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-text-opacity)));
}

.after\:text-danger-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-default-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-primary-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-foreground) / var(--heroui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-secondary-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-foreground) / var(--heroui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-success-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-warning-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.after\:opacity-0:after {
  content: var(--tw-content);
  opacity: 0;
}

.after\:opacity-100:after {
  content: var(--tw-content);
  opacity: 1;
}

.after\:shadow-\[0_1px_0px_0_rgba\(0\,0\,0\,0\.05\)\]:after {
  content: var(--tw-content);
  --tw-shadow: 0 1px 0px 0 #0000000d;
  --tw-shadow-colored: 0 1px 0px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.after\:shadow-small:after {
  content: var(--tw-content);
  --tw-shadow: var(--heroui-box-shadow-small);
  --tw-shadow-colored: var(--heroui-box-shadow-small);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:transition-none:after {
  content: var(--tw-content);
  transition-property: none;
}

.after\:transition-transform:after {
  content: var(--tw-content);
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:\!duration-200:after {
  content: var(--tw-content);
  transition-duration: .2s !important;
}

.after\:duration-150:after {
  content: var(--tw-content);
  transition-duration: .15s;
}

.after\:\!ease-linear:after {
  content: var(--tw-content);
  transition-timing-function: linear !important;
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:content-\[\'\*\'\]:after {
  --tw-content: "*";
  content: var(--tw-content);
}

.after\:transition-background:after {
  content: var(--tw-content);
  transition-property: background;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.after\:transition-width:after {
  content: var(--tw-content);
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.after\:transition-height:after {
  content: var(--tw-content);
  transition-property: height;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.after\:transition-transform-opacity:after {
  content: var(--tw-content);
  transition-property: transform, opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.first\:-ml-0\.5:first-child {
  margin-left: -.125rem;
}

.first\:mt-2:first-child {
  margin-top: .5rem;
}

.first\:rounded-s-full:first-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.first\:rounded-s-full:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.first\:rounded-s-full:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.first\:rounded-s-large:first-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: var(--heroui-radius-large);
  border-bottom-left-radius: var(--heroui-radius-large);
}

.first\:rounded-s-large:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: var(--heroui-radius-large);
  border-bottom-right-radius: var(--heroui-radius-large);
}

.first\:rounded-s-large:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: var(--heroui-radius-large);
  border-bottom-right-radius: var(--heroui-radius-large);
}

.first\:rounded-s-lg:first-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.first\:rounded-s-lg:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.first\:rounded-s-lg:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.first\:rounded-s-medium:first-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: var(--heroui-radius-medium);
  border-bottom-left-radius: var(--heroui-radius-medium);
}

.first\:rounded-s-medium:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: var(--heroui-radius-medium);
  border-bottom-right-radius: var(--heroui-radius-medium);
}

.first\:rounded-s-medium:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: var(--heroui-radius-medium);
  border-bottom-right-radius: var(--heroui-radius-medium);
}

.first\:rounded-s-none:first-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.first\:rounded-s-none:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.first\:rounded-s-none:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.first\:rounded-s-small:first-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: var(--heroui-radius-small);
  border-bottom-left-radius: var(--heroui-radius-small);
}

.first\:rounded-s-small:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: var(--heroui-radius-small);
  border-bottom-right-radius: var(--heroui-radius-small);
}

.first\:rounded-s-small:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: var(--heroui-radius-small);
  border-bottom-right-radius: var(--heroui-radius-small);
}

.first\:before\:rounded-s-lg:first-child:before {
  content: var(--tw-content);
}

.first\:before\:rounded-s-lg:first-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.first\:before\:rounded-s-lg:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.first\:before\:rounded-s-lg:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.last\:rounded-e-full:last-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.last\:rounded-e-full:last-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.last\:rounded-e-full:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.last\:rounded-e-large:last-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: var(--heroui-radius-large);
  border-bottom-right-radius: var(--heroui-radius-large);
}

.last\:rounded-e-large:last-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: var(--heroui-radius-large);
  border-bottom-left-radius: var(--heroui-radius-large);
}

.last\:rounded-e-large:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: var(--heroui-radius-large);
  border-bottom-left-radius: var(--heroui-radius-large);
}

.last\:rounded-e-lg:last-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.last\:rounded-e-lg:last-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.last\:rounded-e-lg:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.last\:rounded-e-medium:last-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: var(--heroui-radius-medium);
  border-bottom-right-radius: var(--heroui-radius-medium);
}

.last\:rounded-e-medium:last-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: var(--heroui-radius-medium);
  border-bottom-left-radius: var(--heroui-radius-medium);
}

.last\:rounded-e-medium:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: var(--heroui-radius-medium);
  border-bottom-left-radius: var(--heroui-radius-medium);
}

.last\:rounded-e-none:last-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.last\:rounded-e-none:last-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.last\:rounded-e-none:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.last\:rounded-e-small:last-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: var(--heroui-radius-small);
  border-bottom-right-radius: var(--heroui-radius-small);
}

.last\:rounded-e-small:last-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: var(--heroui-radius-small);
  border-bottom-left-radius: var(--heroui-radius-small);
}

.last\:rounded-e-small:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: var(--heroui-radius-small);
  border-bottom-left-radius: var(--heroui-radius-small);
}

.last\:before\:rounded-e-lg:last-child:before {
  content: var(--tw-content);
}

.last\:before\:rounded-e-lg:last-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.last\:before\:rounded-e-lg:last-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.last\:before\:rounded-e-lg:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.first-of-type\:rounded-e-none:first-of-type:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.first-of-type\:rounded-e-none:first-of-type:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.first-of-type\:rounded-e-none:first-of-type:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.last-of-type\:rounded-s-none:last-of-type:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.last-of-type\:rounded-s-none:last-of-type:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.last-of-type\:rounded-s-none:last-of-type:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.autofill\:bg-transparent:-webkit-autofill {
  background-color: #0000;
}

.autofill\:bg-transparent:autofill {
  background-color: #0000;
}

.focus-within\:border-danger:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-default-400:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-400) / var(--heroui-default-400-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-default-foreground:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-primary:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-secondary:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-success:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-warning:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.focus-within\:bg-danger-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity)));
}

.focus-within\:bg-primary-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-50) / var(--heroui-primary-50-opacity, var(--tw-bg-opacity)));
}

.focus-within\:bg-secondary-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-50) / var(--heroui-secondary-50-opacity, var(--tw-bg-opacity)));
}

.focus-within\:bg-success-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-50) / var(--heroui-success-50-opacity, var(--tw-bg-opacity)));
}

.focus-within\:bg-warning-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-50) / var(--heroui-warning-50-opacity, var(--tw-bg-opacity)));
}

.focus-within\:after\:w-full:focus-within:after {
  content: var(--tw-content);
  width: 100%;
}

.hover\:-translate-x-0:hover {
  --tw-translate-x: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-danger:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.hover\:border-default:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-border-opacity)));
}

.hover\:border-default-300:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-300) / var(--heroui-default-300-opacity, var(--tw-border-opacity)));
}

.hover\:border-default-400:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-400) / var(--heroui-default-400-opacity, var(--tw-border-opacity)));
}

.hover\:border-primary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.hover\:border-secondary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.hover\:border-success:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.hover\:border-warning:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.hover\:\!bg-foreground:hover {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-bg-opacity))) !important;
}

.hover\:bg-danger:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-danger-50:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-default-100:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-default-200:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-200) / var(--heroui-default-200-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-primary-50:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-50) / var(--heroui-primary-50-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-secondary-50:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-50) / var(--heroui-secondary-50-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-success-50:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-50) / var(--heroui-success-50-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-warning-50:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-50) / var(--heroui-warning-50-opacity, var(--tw-bg-opacity)));
}

.hover\:text-danger-600:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-600) / var(--heroui-danger-600-opacity, var(--tw-text-opacity)));
}

.hover\:text-default-600:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-600) / var(--heroui-default-600-opacity, var(--tw-text-opacity)));
}

.hover\:text-foreground-600:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground-600) / var(--heroui-foreground-600-opacity, var(--tw-text-opacity)));
}

.hover\:text-primary-600:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-600) / var(--heroui-primary-600-opacity, var(--tw-text-opacity)));
}

.hover\:text-secondary-600:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-600) / var(--heroui-secondary-600-opacity, var(--tw-text-opacity)));
}

.hover\:text-success-600:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-600) / var(--heroui-success-600-opacity, var(--tw-text-opacity)));
}

.hover\:text-warning-600:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-600) / var(--heroui-warning-600-opacity, var(--tw-text-opacity)));
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:\!opacity-100:hover {
  opacity: 1 !important;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:after\:bg-danger\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-danger) / .2);
}

.hover\:after\:bg-foreground\/10:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-foreground) / .1);
}

.hover\:after\:bg-primary\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-primary) / .2);
}

.hover\:after\:bg-secondary\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-secondary) / .2);
}

.hover\:after\:bg-success\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-success) / .2);
}

.hover\:after\:bg-warning\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-warning) / .2);
}

.hover\:after\:opacity-100:hover:after {
  content: var(--tw-content);
  opacity: 1;
}

.focus-within\:hover\:border-danger:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.focus-within\:hover\:border-default-foreground:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-border-opacity)));
}

.focus-within\:hover\:border-primary:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.focus-within\:hover\:border-secondary:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.focus-within\:hover\:border-success:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.focus-within\:hover\:border-warning:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.focus-within\:hover\:bg-default-100:hover:focus-within {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-bg-opacity)));
}

.focus\:bg-danger-400\/50:focus {
  background-color: hsl(var(--heroui-danger-400) / .5);
}

.focus\:bg-default-400\/50:focus {
  background-color: hsl(var(--heroui-default-400) / .5);
}

.focus\:bg-primary-400\/50:focus {
  background-color: hsl(var(--heroui-primary-400) / .5);
}

.focus\:bg-secondary-400\/50:focus {
  background-color: hsl(var(--heroui-secondary-400) / .5);
}

.focus\:bg-success-400\/50:focus {
  background-color: hsl(var(--heroui-success-400) / .5);
}

.focus\:bg-warning-400\/50:focus {
  background-color: hsl(var(--heroui-warning-400) / .5);
}

.focus\:underline:focus {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.focus\:shadow-sm:focus {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-visible\:z-10:focus-visible {
  z-index: 10;
}

.focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-focus:focus-visible {
  outline-color: hsl(var(--heroui-focus) / var(--heroui-focus-opacity, 1));
}

.active\:bg-default-200:active {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-200) / var(--heroui-default-200-opacity, var(--tw-bg-opacity)));
}

.active\:bg-default-300:active {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-300) / var(--heroui-default-300-opacity, var(--tw-bg-opacity)));
}

.active\:underline:active {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.active\:\!opacity-70:active {
  opacity: .7 !important;
}

.active\:opacity-disabled:active {
  opacity: var(--heroui-disabled-opacity);
}

.disabled\:cursor-default:disabled {
  cursor: default;
}

.group:hover .group-hover\:pointer-events-auto {
  pointer-events: auto;
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:hidden {
  display: none;
}

.group:hover .group-hover\:border-current {
  border-color: currentColor;
}

.group:hover .group-hover\:text-current {
  color: currentColor;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.has-\[\:disabled\]\:opacity-60:has(:disabled) {
  opacity: .6;
}

.aria-expanded\:scale-\[0\.97\][aria-expanded="true"] {
  --tw-scale-x: .97;
  --tw-scale-y: .97;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.aria-expanded\:opacity-70[aria-expanded="true"] {
  opacity: .7;
}

.data-\[disabled\=true\]\:pointer-events-none[data-disabled="true"] {
  pointer-events: none;
}

.data-\[loaded\=true\]\:pointer-events-auto[data-loaded="true"], .data-\[visible\=true\]\:pointer-events-auto[data-visible="true"] {
  pointer-events: auto;
}

.data-\[placement\=bottom-center\]\:fixed[data-placement="bottom-center"], .data-\[placement\=bottom-left\]\:fixed[data-placement="bottom-left"], .data-\[placement\=bottom-right\]\:fixed[data-placement="bottom-right"], .data-\[placement\=top-center\]\:fixed[data-placement="top-center"], .data-\[placement\=top-left\]\:fixed[data-placement="top-left"], .data-\[placement\=top-right\]\:fixed[data-placement="top-right"] {
  position: fixed;
}

.data-\[placement\=bottom-center\]\:bottom-0[data-placement="bottom-center"] {
  bottom: 0;
}

.data-\[placement\=bottom-center\]\:left-0[data-placement="bottom-center"] {
  left: 0;
}

.data-\[placement\=bottom-center\]\:left-1\/2[data-placement="bottom-center"] {
  left: 50%;
}

.data-\[placement\=bottom-center\]\:right-0[data-placement="bottom-center"] {
  right: 0;
}

.data-\[placement\=bottom-left\]\:bottom-0[data-placement="bottom-left"] {
  bottom: 0;
}

.data-\[placement\=bottom-left\]\:left-0[data-placement="bottom-left"] {
  left: 0;
}

.data-\[placement\=bottom-right\]\:bottom-0[data-placement="bottom-right"] {
  bottom: 0;
}

.data-\[placement\=bottom-right\]\:right-0[data-placement="bottom-right"] {
  right: 0;
}

.data-\[placement\=top-center\]\:left-0[data-placement="top-center"] {
  left: 0;
}

.data-\[placement\=top-center\]\:left-1\/2[data-placement="top-center"] {
  left: 50%;
}

.data-\[placement\=top-center\]\:right-0[data-placement="top-center"] {
  right: 0;
}

.data-\[placement\=top-center\]\:top-0[data-placement="top-center"] {
  top: 0;
}

.data-\[placement\=top-left\]\:left-0[data-placement="top-left"] {
  left: 0;
}

.data-\[placement\=top-left\]\:top-0[data-placement="top-left"] {
  top: 0;
}

.data-\[placement\=top-right\]\:right-0[data-placement="top-right"] {
  right: 0;
}

.data-\[placement\=top-right\]\:top-0[data-placement="top-right"] {
  top: 0;
}

.data-\[focus-visible\=true\]\:z-10[data-focus-visible="true"], .data-\[focused\=true\]\:z-10[data-focused="true"] {
  z-index: 10;
}

.data-\[placement\=bottom-left\]\:mx-auto[data-placement="bottom-left"], .data-\[placement\=bottom-right\]\:mx-auto[data-placement="bottom-right"], .data-\[placement\=top-left\]\:mx-auto[data-placement="top-left"], .data-\[placement\=top-right\]\:mx-auto[data-placement="top-right"] {
  margin-left: auto;
  margin-right: auto;
}

.data-\[has-label\=true\]\:mt-\[calc\(theme\(fontSize\.small\)_\+_10px\)\][data-has-label="true"] {
  margin-top: calc(var(--heroui-font-size-small)  + 10px);
}

.data-\[has-label\=true\]\:mt-\[calc\(theme\(fontSize\.small\)_\+_12px\)\][data-has-label="true"] {
  margin-top: calc(var(--heroui-font-size-small)  + 12px);
}

.data-\[has-label\=true\]\:mt-\[calc\(theme\(fontSize\.small\)_\+_8px\)\][data-has-label="true"] {
  margin-top: calc(var(--heroui-font-size-small)  + 8px);
}

.data-\[open\=true\]\:block[data-open="true"] {
  display: block;
}

.data-\[open\=true\]\:flex[data-open="true"], .data-\[placement\=bottom-center\]\:flex[data-placement="bottom-center"], .data-\[placement\=bottom-left\]\:flex[data-placement="bottom-left"], .data-\[placement\=bottom-right\]\:flex[data-placement="bottom-right"], .data-\[placement\=top-center\]\:flex[data-placement="top-center"], .data-\[placement\=top-left\]\:flex[data-placement="top-left"], .data-\[placement\=top-right\]\:flex[data-placement="top-right"] {
  display: flex;
}

.data-\[hidden\=true\]\:hidden[data-hidden="true"], .data-\[inert\=true\]\:hidden[data-inert="true"] {
  display: none;
}

.data-\[justify\=end\]\:flex-grow[data-justify="end"], .data-\[justify\=start\]\:flex-grow[data-justify="start"] {
  flex-grow: 1;
}

.data-\[justify\=end\]\:basis-0[data-justify="end"], .data-\[justify\=start\]\:basis-0[data-justify="start"] {
  flex-basis: 0;
}

.data-\[animation\=exiting\]\:data-\[placement\=bottom-center\]\:translate-y-28[data-placement="bottom-center"][data-animation="exiting"] {
  --tw-translate-y: 7rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[animation\=exiting\]\:data-\[placement\=bottom-left\]\:-translate-x-28[data-placement="bottom-left"][data-animation="exiting"] {
  --tw-translate-x: -7rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[animation\=exiting\]\:data-\[placement\=bottom-right\]\:translate-x-28[data-placement="bottom-right"][data-animation="exiting"] {
  --tw-translate-x: 7rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[animation\=exiting\]\:data-\[placement\=top-center\]\:-translate-y-28[data-placement="top-center"][data-animation="exiting"] {
  --tw-translate-y: -7rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[animation\=exiting\]\:data-\[placement\=top-left\]\:-translate-x-28[data-placement="top-left"][data-animation="exiting"] {
  --tw-translate-x: -7rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[animation\=exiting\]\:data-\[placement\=top-right\]\:translate-x-28[data-placement="top-right"][data-animation="exiting"] {
  --tw-translate-x: 7rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[focus-visible\=true\]\:-translate-x-3[data-focus-visible="true"], .data-\[hover\=true\]\:-translate-x-3[data-hover="true"] {
  --tw-translate-x: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[hover\=true\]\:translate-x-0[data-hover="true"] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=bottom-center\]\:-translate-x-1\/2[data-placement="bottom-center"], .data-\[placement\=top-center\]\:-translate-x-1\/2[data-placement="top-center"] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[before\=true\]\:rotate-180[data-before="true"], .data-\[direction\=ascending\]\:rotate-180[data-direction="ascending"] {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[open\=true\]\:-rotate-90[data-open="true"] {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[open\=true\]\:rotate-180[data-open="true"] {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[active\=true\]\:scale-100[data-active="true"] {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[active\=true\]\:scale-110[data-active="true"] {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[invisible\=true\]\:scale-0[data-invisible="true"] {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[pressed\=true\]\:scale-100[data-pressed="true"] {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[pressed\=true\]\:scale-\[0\.97\][data-pressed="true"] {
  --tw-scale-x: .97;
  --tw-scale-y: .97;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[animation\=exiting\]\:transform[data-animation="exiting"] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[disabled\=true\]\:cursor-default[data-disabled="true"] {
  cursor: default;
}

.data-\[disabled\=true\]\:cursor-not-allowed[data-disabled="true"] {
  cursor: not-allowed;
}

.data-\[dragging\=true\]\:cursor-grabbing[data-dragging="true"] {
  cursor: grabbing;
}

.data-\[readonly\=true\]\:cursor-default[data-readonly="true"] {
  cursor: default;
}

.data-\[sortable\=true\]\:cursor-pointer[data-sortable="true"] {
  cursor: pointer;
}

.data-\[unavailable\=true\]\:cursor-default[data-unavailable="true"] {
  cursor: default;
}

.data-\[visible\=true\]\:cursor-pointer[data-visible="true"] {
  cursor: pointer;
}

.data-\[orientation\=horizontal\]\:flex-row[data-orientation="horizontal"] {
  flex-direction: row;
}

.data-\[placement\=bottom-center\]\:flex-col[data-placement="bottom-center"], .data-\[placement\=bottom-left\]\:flex-col[data-placement="bottom-left"], .data-\[placement\=bottom-right\]\:flex-col[data-placement="bottom-right"], .data-\[placement\=top-center\]\:flex-col[data-placement="top-center"], .data-\[placement\=top-left\]\:flex-col[data-placement="top-left"], .data-\[placement\=top-right\]\:flex-col[data-placement="top-right"] {
  flex-direction: column;
}

.data-\[has-helper\=true\]\:items-start[data-has-helper="true"] {
  align-items: flex-start;
}

.data-\[justify\=start\]\:justify-start[data-justify="start"] {
  justify-content: flex-start;
}

.data-\[justify\=end\]\:justify-end[data-justify="end"] {
  justify-content: flex-end;
}

.data-\[justify\=center\]\:justify-center[data-justify="center"] {
  justify-content: center;
}

.data-\[loaded\=true\]\:overflow-visible[data-loaded="true"] {
  overflow: visible;
}

.data-\[has-multiple-rows\=true\]\:rounded-large[data-has-multiple-rows="true"] {
  border-radius: var(--heroui-radius-large);
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:rounded-full[data-range-selection="true"][data-selection-end="true"][data-selected="true"], .data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:rounded-full[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  border-radius: 9999px;
}

.data-\[menu-open\=true\]\:border-none[data-menu-open="true"] {
  border-style: none;
}

.data-\[active\=true\]\:border-danger[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-danger-400[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger-400) / var(--heroui-danger-400-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-default-300[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-300) / var(--heroui-default-300-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-default-400[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-400) / var(--heroui-default-400-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-foreground[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-primary[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-secondary[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-success[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-warning[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-danger[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-default-400[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-400) / var(--heroui-default-400-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-default-foreground[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-primary[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-secondary[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-success[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-warning[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-danger[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-default[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-default-400[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-400) / var(--heroui-default-400-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-primary[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-secondary[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-success[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-warning[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-danger[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-default-400[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-400) / var(--heroui-default-400-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-default-foreground[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-primary[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-secondary[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-success[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-warning[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:bg-danger[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-danger-100[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-100) / var(--heroui-danger-100-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-danger-200[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-200) / var(--heroui-danger-200-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-default-200[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-200) / var(--heroui-default-200-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-default-400[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-400) / var(--heroui-default-400-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-primary[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-primary-200[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-200) / var(--heroui-primary-200-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-secondary[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-secondary-200[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-200) / var(--heroui-secondary-200-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-success[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-success-200[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-200) / var(--heroui-success-200-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-warning[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-warning-200[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-200) / var(--heroui-warning-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:\!bg-danger[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity))) !important;
}

.data-\[hover\=true\]\:\!bg-danger-100[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--heroui-danger-100) / var(--heroui-danger-100-opacity, var(--tw-bg-opacity))) !important;
}

.data-\[hover\=true\]\:\!bg-default[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-bg-opacity))) !important;
}

.data-\[hover\=true\]\:\!bg-primary[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity))) !important;
}

.data-\[hover\=true\]\:\!bg-secondary[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity))) !important;
}

.data-\[hover\=true\]\:\!bg-success[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity))) !important;
}

.data-\[hover\=true\]\:\!bg-warning[data-hover="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity))) !important;
}

.data-\[hover\=true\]\:bg-content2[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-content2) / var(--heroui-content2-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-danger[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-danger-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-100) / var(--heroui-danger-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-danger-50[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-danger\/20[data-hover="true"] {
  background-color: hsl(var(--heroui-danger) / .2);
}

.data-\[hover\=true\]\:bg-default[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-default-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-default-200[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-200) / var(--heroui-default-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-default\/40[data-hover="true"] {
  background-color: hsl(var(--heroui-default) / .4);
}

.data-\[hover\=true\]\:bg-foreground-200[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-foreground-200) / var(--heroui-foreground-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-primary[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-primary-50[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-50) / var(--heroui-primary-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-primary\/20[data-hover="true"] {
  background-color: hsl(var(--heroui-primary) / .2);
}

.data-\[hover\=true\]\:bg-secondary[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-secondary-50[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-50) / var(--heroui-secondary-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-secondary\/20[data-hover="true"] {
  background-color: hsl(var(--heroui-secondary) / .2);
}

.data-\[hover\=true\]\:bg-success[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-success-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-100) / var(--heroui-success-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-success-50[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-50) / var(--heroui-success-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-success\/20[data-hover="true"] {
  background-color: hsl(var(--heroui-success) / .2);
}

.data-\[hover\=true\]\:bg-transparent[data-hover="true"] {
  background-color: #0000;
}

.data-\[hover\=true\]\:bg-warning[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-warning-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-100) / var(--heroui-warning-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-warning-50[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-50) / var(--heroui-warning-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-warning\/20[data-hover="true"] {
  background-color: hsl(var(--heroui-warning) / .2);
}

.data-\[hover\]\:bg-danger-200[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-200) / var(--heroui-danger-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-danger-50[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-default-100[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-primary-200[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-200) / var(--heroui-primary-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-primary-50[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-50) / var(--heroui-primary-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-secondary-200[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-200) / var(--heroui-secondary-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-secondary-50[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-50) / var(--heroui-secondary-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-success-200[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-200) / var(--heroui-success-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-success-50[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-50) / var(--heroui-success-50-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-warning-100[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-100) / var(--heroui-warning-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\]\:bg-warning-200[data-hover] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-200) / var(--heroui-warning-200-opacity, var(--tw-bg-opacity)));
}

.data-\[in-range\=false\]\:bg-default-200[data-in-range="false"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-200) / var(--heroui-default-200-opacity, var(--tw-bg-opacity)));
}

.data-\[in-range\=true\]\:bg-background\/50[data-in-range="true"] {
  background-color: hsl(var(--heroui-background) / .5);
}

.data-\[in-range\=true\]\:bg-danger[data-in-range="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[in-range\=true\]\:bg-foreground[data-in-range="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[in-range\=true\]\:bg-primary[data-in-range="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[in-range\=true\]\:bg-secondary[data-in-range="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[in-range\=true\]\:bg-success[data-in-range="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[in-range\=true\]\:bg-warning[data-in-range="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[loaded\=true\]\:\!bg-transparent[data-loaded="true"] {
  background-color: #0000 !important;
}

.data-\[selected\=true\]\:bg-danger[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-default[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-foreground[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-primary[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-secondary[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-success[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-warning[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:bg-danger[data-hover="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:bg-foreground[data-hover="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:bg-primary[data-hover="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:bg-secondary[data-hover="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:bg-success[data-hover="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:bg-warning[data-hover="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:bg-transparent[data-range-selection="true"][data-selected="true"], .data-\[selected\=true\]\:data-\[range-selection\=true\]\:data-\[outside-month\=true\]\:bg-transparent[data-outside-month="true"][data-range-selection="true"][data-selected="true"] {
  background-color: #0000;
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:bg-danger[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:bg-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:bg-primary[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:bg-secondary[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:bg-success[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:bg-warning[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:bg-danger[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:bg-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:bg-primary[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:bg-secondary[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:bg-success[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:bg-warning[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[has-end-content\=true\]\:pe-1\.5[data-has-end-content="true"]:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .375rem;
}

.data-\[has-end-content\=true\]\:pe-1\.5[data-has-end-content="true"]:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: .375rem;
}

.data-\[has-end-content\=true\]\:pe-1\.5[data-has-end-content="true"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: .375rem;
}

.data-\[has-helper\=true\]\:pb-\[calc\(theme\(fontSize\.tiny\)_\+8px\)\][data-has-helper="true"], .data-\[has-helper\=true\]\:pb-\[calc\(theme\(fontSize\.tiny\)_\+_8px\)\][data-has-helper="true"] {
  padding-bottom: calc(var(--heroui-font-size-tiny)  + 8px);
}

.data-\[has-start-content\=true\]\:ps-1\.5[data-has-start-content="true"]:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: .375rem;
}

.data-\[has-start-content\=true\]\:ps-1\.5[data-has-start-content="true"]:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .375rem;
}

.data-\[has-start-content\=true\]\:ps-1\.5[data-has-start-content="true"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .375rem;
}

.data-\[has-title\=true\]\:pt-1[data-has-title="true"] {
  padding-top: .25rem;
}

.data-\[active\=true\]\:font-semibold[data-active="true"] {
  font-weight: 600;
}

.data-\[active\=true\]\:text-danger-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-default-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-primary-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-foreground) / var(--heroui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-secondary-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-foreground) / var(--heroui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-success-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-warning-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[disabled\=true\]\:text-default-300[data-disabled="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-300) / var(--heroui-default-300-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:data-\[placeholder\=true\]\:text-danger-300[data-placeholder="true"][data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-300) / var(--heroui-danger-300-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:data-\[placeholder\=true\]\:text-foreground-500[data-placeholder="true"][data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground-500) / var(--heroui-foreground-500-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:data-\[placeholder\=true\]\:text-primary-300[data-placeholder="true"][data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-300) / var(--heroui-primary-300-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:data-\[placeholder\=true\]\:text-secondary-300[data-placeholder="true"][data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-300) / var(--heroui-secondary-300-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:data-\[placeholder\=true\]\:text-success-400[data-placeholder="true"][data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-400) / var(--heroui-success-400-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:data-\[placeholder\=true\]\:text-warning-400[data-placeholder="true"][data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-400) / var(--heroui-warning-400-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:text-danger[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:text-foreground[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:text-primary[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:text-secondary[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:text-success-600[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-600) / var(--heroui-success-600-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:text-warning-600[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-600) / var(--heroui-warning-600-opacity, var(--tw-text-opacity)));
}

.data-\[has-value\=true\]\:text-default-foreground[data-has-value="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:\!text-danger-foreground[data-hover="true"] {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, var(--tw-text-opacity))) !important;
}

.data-\[hover\=true\]\:\!text-primary-foreground[data-hover="true"] {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--heroui-primary-foreground) / var(--heroui-primary-foreground-opacity, var(--tw-text-opacity))) !important;
}

.data-\[hover\=true\]\:\!text-secondary-foreground[data-hover="true"] {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--heroui-secondary-foreground) / var(--heroui-secondary-foreground-opacity, var(--tw-text-opacity))) !important;
}

.data-\[hover\=true\]\:\!text-success-foreground[data-hover="true"] {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity))) !important;
}

.data-\[hover\=true\]\:\!text-warning-foreground[data-hover="true"] {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, var(--tw-text-opacity))) !important;
}

.data-\[hover\=true\]\:text-danger[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-danger-500[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-500) / var(--heroui-danger-500-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-danger-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-default-500[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-500) / var(--heroui-default-500-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-default-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-foreground-400[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground-400) / var(--heroui-foreground-400-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-foreground-600[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground-600) / var(--heroui-foreground-600-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-primary[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-primary-400[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-400) / var(--heroui-primary-400-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-primary-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-foreground) / var(--heroui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-secondary[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-secondary-400[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-400) / var(--heroui-secondary-400-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-secondary-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-foreground) / var(--heroui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-success[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-success-600[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-600) / var(--heroui-success-600-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-success-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-warning[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-warning-600[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-600) / var(--heroui-warning-600-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-warning-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[invalid\=true\]\:data-\[editable\=true\]\:text-danger[data-editable="true"][data-invalid="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[invalid\=true\]\:text-danger-300[data-invalid="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-300) / var(--heroui-danger-300-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:text-background[data-hover="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-background) / var(--heroui-background-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:text-danger-foreground[data-hover="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:text-primary-foreground[data-hover="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-foreground) / var(--heroui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:text-secondary-foreground[data-hover="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-foreground) / var(--heroui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:text-success-foreground[data-hover="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[hover\=true\]\:text-warning-foreground[data-hover="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:data-\[outside-month\=true\]\:text-default-300[data-outside-month="true"][data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-300) / var(--heroui-default-300-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-danger-500[data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-500) / var(--heroui-danger-500-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-foreground[data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-primary[data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-secondary[data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-success-600[data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-600) / var(--heroui-success-600-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-warning-500[data-range-selection="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-500) / var(--heroui-warning-500-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-background[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-background) / var(--heroui-background-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-danger-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-primary-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-foreground) / var(--heroui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-secondary-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-foreground) / var(--heroui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-success-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-warning-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-background[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-background) / var(--heroui-background-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-danger-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-primary-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-foreground) / var(--heroui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-secondary-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-foreground) / var(--heroui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-success-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-warning-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-background[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-background) / var(--heroui-background-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-danger[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-danger-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-default-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-primary[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-primary-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-foreground) / var(--heroui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-secondary[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-secondary-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-foreground) / var(--heroui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-success-600[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-600) / var(--heroui-success-600-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-success-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-warning-600[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-600) / var(--heroui-warning-600-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-warning-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[unavailable\=true\]\:text-default-300[data-unavailable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-300) / var(--heroui-default-300-opacity, var(--tw-text-opacity)));
}

.data-\[unavailable\=true\]\:line-through[data-unavailable="true"] {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}

.data-\[animation\=exiting\]\:opacity-0[data-animation="exiting"], .data-\[disabled\=true\]\:data-\[outside-month\=true\]\:opacity-0[data-outside-month="true"][data-disabled="true"] {
  opacity: 0;
}

.data-\[disabled\=true\]\:opacity-30[data-disabled="true"] {
  opacity: .3;
}

.data-\[hover-unselected\=true\]\:opacity-disabled[data-hover-unselected="true"] {
  opacity: var(--heroui-disabled-opacity);
}

.data-\[hover\=true\]\:opacity-70[data-hover="true"] {
  opacity: .7;
}

.data-\[hover\=true\]\:opacity-hover[data-hover="true"] {
  opacity: var(--heroui-hover-opacity);
}

.data-\[in-range\=true\]\:opacity-100[data-in-range="true"] {
  opacity: 1;
}

.data-\[invisible\=true\]\:opacity-0[data-invisible="true"] {
  opacity: 0;
}

.data-\[loaded\=true\]\:opacity-100[data-loaded="true"], .data-\[moving\]\:opacity-100[data-moving] {
  opacity: 1;
}

.data-\[pressed\=true\]\:opacity-50[data-pressed="true"] {
  opacity: .5;
}

.data-\[pressed\=true\]\:opacity-70[data-pressed="true"] {
  opacity: .7;
}

.data-\[pressed\=true\]\:opacity-disabled[data-pressed="true"] {
  opacity: var(--heroui-disabled-opacity);
}

.data-\[visible\=true\]\:opacity-100[data-visible="true"] {
  opacity: 1;
}

.data-\[active\=true\]\:shadow-md[data-active="true"] {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[hover\=true\]\:shadow-lg[data-hover="true"] {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[selected\=true\]\:data-\[selection-end\=true\]\:shadow-md[data-selection-end="true"][data-selected="true"], .data-\[selected\=true\]\:data-\[selection-start\=true\]\:shadow-md[data-selection-start="true"][data-selected="true"], .data-\[selected\=true\]\:shadow-md[data-selected="true"] {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[selected\=true\]\:shadow-none[data-selected="true"] {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[active\=true\]\:shadow-danger\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--heroui-danger) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-default\/50[data-active="true"] {
  --tw-shadow-color: hsl(var(--heroui-default) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-primary\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--heroui-primary) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-secondary\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--heroui-secondary) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-success\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--heroui-success) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-warning\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--heroui-warning) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-danger\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--heroui-danger) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-default\/50[data-hover="true"] {
  --tw-shadow-color: hsl(var(--heroui-default) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-primary\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--heroui-primary) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-secondary\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--heroui-secondary) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-success\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--heroui-success) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-warning\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--heroui-warning) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selected\=true\]\:shadow-danger\/40[data-selected="true"] {
  --tw-shadow-color: hsl(var(--heroui-danger) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selected\=true\]\:shadow-foreground\/40[data-selected="true"] {
  --tw-shadow-color: hsl(var(--heroui-foreground) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selected\=true\]\:shadow-primary\/40[data-selected="true"] {
  --tw-shadow-color: hsl(var(--heroui-primary) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selected\=true\]\:shadow-secondary\/40[data-selected="true"] {
  --tw-shadow-color: hsl(var(--heroui-secondary) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selected\=true\]\:shadow-success\/40[data-selected="true"] {
  --tw-shadow-color: hsl(var(--heroui-success) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selected\=true\]\:shadow-warning\/40[data-selected="true"] {
  --tw-shadow-color: hsl(var(--heroui-warning) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[focus-visible\=true\]\:outline-2[data-focus-visible="true"] {
  outline-width: 2px;
}

.data-\[focus-visible\=true\]\:outline-offset-2[data-focus-visible="true"] {
  outline-offset: 2px;
}

.data-\[focus-visible\=true\]\:outline-focus[data-focus-visible="true"] {
  outline-color: hsl(var(--heroui-focus) / var(--heroui-focus-opacity, 1));
}

.data-\[focus-visible\]\:outline-danger-foreground[data-focus-visible] {
  outline-color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-default-foreground[data-focus-visible] {
  outline-color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-primary-foreground[data-focus-visible] {
  outline-color: hsl(var(--heroui-primary-foreground) / var(--heroui-primary-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-secondary-foreground[data-focus-visible] {
  outline-color: hsl(var(--heroui-secondary-foreground) / var(--heroui-secondary-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-success-foreground[data-focus-visible] {
  outline-color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-warning-foreground[data-focus-visible] {
  outline-color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, 1));
}

.data-\[menu-open\=true\]\:backdrop-blur-xl[data-menu-open="true"] {
  --tw-backdrop-blur: blur(24px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.data-\[disabled\=true\]\:transition-none[data-disabled="true"] {
  transition-property: none;
}

.data-\[hover\=true\]\:transition-colors[data-hover="true"] {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.data-\[moving\=true\]\:transition-transform[data-moving="true"] {
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.data-\[animation\=exiting\]\:delay-100[data-animation="exiting"] {
  transition-delay: .1s;
}

.data-\[animation\=exiting\]\:duration-200[data-animation="exiting"] {
  transition-duration: .2s;
}

.data-\[hide-scroll\=true\]\:scrollbar-hide[data-hide-scroll="true"] {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.data-\[hide-scroll\=true\]\:scrollbar-hide[data-hide-scroll="true"]::-webkit-scrollbar {
  display: none;
}

.data-\[top-bottom-scroll\=true\]\:\[mask-image\:linear-gradient\(\#000\,\#000\,transparent_0\,\#000_var\(--scroll-shadow-size\)\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-top-bottom-scroll="true"] {
  -webkit-mask-image: linear-gradient(#000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
  -webkit-mask-image: linear-gradient(#000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(#000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[top-scroll\=true\]\:\[mask-image\:linear-gradient\(0deg\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-top-scroll="true"] {
  -webkit-mask-image: linear-gradient(0deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  -webkit-mask-image: linear-gradient(0deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(0deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[bottom-scroll\=true\]\:\[mask-image\:linear-gradient\(180deg\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-bottom-scroll="true"] {
  -webkit-mask-image: linear-gradient(180deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  -webkit-mask-image: linear-gradient(180deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(180deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[left-scroll\=true\]\:\[mask-image\:linear-gradient\(270deg\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-left-scroll="true"] {
  -webkit-mask-image: linear-gradient(270deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  -webkit-mask-image: linear-gradient(270deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(270deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[right-scroll\=true\]\:\[mask-image\:linear-gradient\(90deg\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-right-scroll="true"] {
  -webkit-mask-image: linear-gradient(90deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  -webkit-mask-image: linear-gradient(90deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(90deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[left-right-scroll\=true\]\:\[mask-image\:linear-gradient\(to_right\,\#000\,\#000\,transparent_0\,\#000_var\(--scroll-shadow-size\)\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-left-right-scroll="true"] {
  -webkit-mask-image: linear-gradient(to right, #000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
  -webkit-mask-image: linear-gradient(to right, #000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(to right, #000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[placement\=bottom-end\]\:before\:-top-\[calc\(theme\(spacing\.5\)\/4_-_1\.5px\)\][data-placement="bottom-end"]:before {
  content: var(--tw-content);
  top: calc(1.5px - .3125rem);
}

.data-\[placement\=bottom-end\]\:before\:right-3[data-placement="bottom-end"]:before {
  content: var(--tw-content);
  right: .75rem;
}

.data-\[placement\=bottom-start\]\:before\:-top-\[calc\(theme\(spacing\.5\)\/4_-_1\.5px\)\][data-placement="bottom-start"]:before {
  content: var(--tw-content);
  top: calc(1.5px - .3125rem);
}

.data-\[placement\=bottom-start\]\:before\:left-3[data-placement="bottom-start"]:before {
  content: var(--tw-content);
  left: .75rem;
}

.data-\[placement\=bottom\]\:before\:-top-\[calc\(theme\(spacing\.5\)\/4_-_1\.5px\)\][data-placement="bottom"]:before {
  content: var(--tw-content);
  top: calc(1.5px - .3125rem);
}

.data-\[placement\=bottom\]\:before\:left-1\/2[data-placement="bottom"]:before {
  content: var(--tw-content);
  left: 50%;
}

.data-\[placement\=left-end\]\:before\:-right-\[calc\(theme\(spacing\.5\)\/4_-_3px\)\][data-placement="left-end"]:before {
  content: var(--tw-content);
  right: calc(3px - .3125rem);
}

.data-\[placement\=left-end\]\:before\:bottom-1\/4[data-placement="left-end"]:before {
  content: var(--tw-content);
  bottom: 25%;
}

.data-\[placement\=left-start\]\:before\:-right-\[calc\(theme\(spacing\.5\)\/4_-_3px\)\][data-placement="left-start"]:before {
  content: var(--tw-content);
  right: calc(3px - .3125rem);
}

.data-\[placement\=left-start\]\:before\:top-1\/4[data-placement="left-start"]:before {
  content: var(--tw-content);
  top: 25%;
}

.data-\[placement\=left\]\:before\:-right-\[calc\(theme\(spacing\.5\)\/4_-_2px\)\][data-placement="left"]:before {
  content: var(--tw-content);
  right: calc(2px - .3125rem);
}

.data-\[placement\=left\]\:before\:top-1\/2[data-placement="left"]:before {
  content: var(--tw-content);
  top: 50%;
}

.data-\[placement\=right-end\]\:before\:-left-\[calc\(theme\(spacing\.5\)\/4_-_3px\)\][data-placement="right-end"]:before {
  content: var(--tw-content);
  left: calc(3px - .3125rem);
}

.data-\[placement\=right-end\]\:before\:bottom-1\/4[data-placement="right-end"]:before {
  content: var(--tw-content);
  bottom: 25%;
}

.data-\[placement\=right-start\]\:before\:-left-\[calc\(theme\(spacing\.5\)\/4_-_3px\)\][data-placement="right-start"]:before {
  content: var(--tw-content);
  left: calc(3px - .3125rem);
}

.data-\[placement\=right-start\]\:before\:top-1\/4[data-placement="right-start"]:before {
  content: var(--tw-content);
  top: 25%;
}

.data-\[placement\=right\]\:before\:-left-\[calc\(theme\(spacing\.5\)\/4_-_2px\)\][data-placement="right"]:before {
  content: var(--tw-content);
  left: calc(2px - .3125rem);
}

.data-\[placement\=right\]\:before\:top-1\/2[data-placement="right"]:before {
  content: var(--tw-content);
  top: 50%;
}

.data-\[placement\=top-end\]\:before\:-bottom-\[calc\(theme\(spacing\.5\)\/4_-_1\.5px\)\][data-placement="top-end"]:before {
  content: var(--tw-content);
  bottom: calc(1.5px - .3125rem);
}

.data-\[placement\=top-end\]\:before\:right-3[data-placement="top-end"]:before {
  content: var(--tw-content);
  right: .75rem;
}

.data-\[placement\=top-start\]\:before\:-bottom-\[calc\(theme\(spacing\.5\)\/4_-_1\.5px\)\][data-placement="top-start"]:before {
  content: var(--tw-content);
  bottom: calc(1.5px - .3125rem);
}

.data-\[placement\=top-start\]\:before\:left-3[data-placement="top-start"]:before {
  content: var(--tw-content);
  left: .75rem;
}

.data-\[placement\=top\]\:before\:-bottom-\[calc\(theme\(spacing\.5\)\/4_-_1\.5px\)\][data-placement="top"]:before {
  content: var(--tw-content);
  bottom: calc(1.5px - .3125rem);
}

.data-\[placement\=top\]\:before\:left-1\/2[data-placement="top"]:before {
  content: var(--tw-content);
  left: 50%;
}

.data-\[loaded\=true\]\:before\:-z-10[data-loaded="true"]:before {
  content: var(--tw-content);
  z-index: -10;
}

.data-\[arrow\=true\]\:before\:block[data-arrow="true"]:before {
  content: var(--tw-content);
  display: block;
}

.data-\[outside-month\=true\]\:before\:hidden[data-outside-month="true"]:before {
  content: var(--tw-content);
  display: none;
}

.data-\[placement\=bottom\]\:before\:-translate-x-1\/2[data-placement="bottom"]:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=left\]\:before\:-translate-y-1\/2[data-placement="left"]:before, .data-\[placement\=right\]\:before\:-translate-y-1\/2[data-placement="right"]:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=top\]\:before\:-translate-x-1\/2[data-placement="top"]:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[loaded\=true\]\:before\:animate-none[data-loaded="true"]:before {
  content: var(--tw-content);
  animation: none;
}

.data-\[range-end\=true\]\:before\:rounded-e-full[data-range-end="true"]:before {
  content: var(--tw-content);
}

.data-\[range-end\=true\]\:before\:rounded-e-full[data-range-end="true"]:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.data-\[range-end\=true\]\:before\:rounded-e-full[data-range-end="true"]:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.data-\[range-end\=true\]\:before\:rounded-e-full[data-range-end="true"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.data-\[range-start\=true\]\:before\:rounded-s-full[data-range-start="true"]:before {
  content: var(--tw-content);
}

.data-\[range-start\=true\]\:before\:rounded-s-full[data-range-start="true"]:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.data-\[range-start\=true\]\:before\:rounded-s-full[data-range-start="true"]:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.data-\[range-start\=true\]\:before\:rounded-s-full[data-range-start="true"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.data-\[selection-end\=true\]\:before\:rounded-e-full[data-selection-end="true"]:before {
  content: var(--tw-content);
}

.data-\[selection-end\=true\]\:before\:rounded-e-full[data-selection-end="true"]:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.data-\[selection-end\=true\]\:before\:rounded-e-full[data-selection-end="true"]:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.data-\[selection-end\=true\]\:before\:rounded-e-full[data-selection-end="true"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.data-\[selection-start\=true\]\:before\:rounded-s-full[data-selection-start="true"]:before {
  content: var(--tw-content);
}

.data-\[selection-start\=true\]\:before\:rounded-s-full[data-selection-start="true"]:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.data-\[selection-start\=true\]\:before\:rounded-s-full[data-selection-start="true"]:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.data-\[selection-start\=true\]\:before\:rounded-s-full[data-selection-start="true"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-danger-50[data-range-selection="true"][data-selected="true"]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-foreground\/10[data-range-selection="true"][data-selected="true"]:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-foreground) / .1);
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-primary-50[data-range-selection="true"][data-selected="true"]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-50) / var(--heroui-primary-50-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-secondary-50[data-range-selection="true"][data-selected="true"]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-50) / var(--heroui-secondary-50-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-success-100[data-range-selection="true"][data-selected="true"]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-100) / var(--heroui-success-100-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-warning-100[data-range-selection="true"][data-selected="true"]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-100) / var(--heroui-warning-100-opacity, var(--tw-bg-opacity)));
}

.data-\[loaded\=true\]\:before\:opacity-0[data-loaded="true"]:before {
  content: var(--tw-content);
  opacity: 0;
}

.data-\[selected\=true\]\:before\:opacity-100[data-selected="true"]:before {
  content: var(--tw-content);
  opacity: 1;
}

.data-\[active\=true\]\:after\:w-full[data-active="true"]:after, .data-\[focus\=true\]\:after\:w-full[data-focus="true"]:after, .data-\[open\=true\]\:after\:w-full[data-open="true"]:after {
  content: var(--tw-content);
  width: 100%;
}

.data-\[dragging\=true\]\:after\:scale-100[data-dragging="true"]:after {
  content: var(--tw-content);
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[dragging\=true\]\:after\:scale-80[data-dragging="true"]:after {
  content: var(--tw-content);
  --tw-scale-x: .8;
  --tw-scale-y: .8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[active\=true\]\:after\:bg-danger-400[data-active="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-400) / var(--heroui-danger-400-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-danger[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-foreground[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-primary[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-secondary[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-success[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-warning[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[loaded\=true\]\:after\:opacity-0[data-loaded="true"]:after {
  content: var(--tw-content);
  opacity: 0;
}

.data-\[selected\=true\]\:after\:opacity-100[data-selected="true"]:after {
  content: var(--tw-content);
  opacity: 1;
}

.data-\[selectable\=true\]\:focus\:border-danger:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-default:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-primary:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-secondary:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-success:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-warning:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[invalid\=true\]\:focus\:bg-danger-400\/50:focus[data-invalid="true"] {
  background-color: hsl(var(--heroui-danger-400) / .5);
}

.data-\[selectable\=true\]\:focus\:bg-danger:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-danger\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--heroui-danger) / .2);
}

.data-\[selectable\=true\]\:focus\:bg-default:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-default-100:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-default\/40:focus[data-selectable="true"] {
  background-color: hsl(var(--heroui-default) / .4);
}

.data-\[selectable\=true\]\:focus\:bg-primary:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-primary\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--heroui-primary) / .2);
}

.data-\[selectable\=true\]\:focus\:bg-secondary:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-secondary\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--heroui-secondary) / .2);
}

.data-\[selectable\=true\]\:focus\:bg-success:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-success\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--heroui-success) / .2);
}

.data-\[selectable\=true\]\:focus\:bg-warning:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-warning\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--heroui-warning) / .2);
}

.data-\[editable\=true\]\:focus\:text-danger:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:focus\:text-default-foreground:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:focus\:text-primary:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:focus\:text-secondary:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:focus\:text-success:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:focus\:text-success-600:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-600) / var(--heroui-success-600-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:focus\:text-warning:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-text-opacity)));
}

.data-\[editable\=true\]\:focus\:text-warning-600:focus[data-editable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-600) / var(--heroui-warning-600-opacity, var(--tw-text-opacity)));
}

.data-\[invalid\=true\]\:data-\[editable\=true\]\:focus\:text-danger:focus[data-editable="true"][data-invalid="true"], .data-\[selectable\=true\]\:focus\:text-danger:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-danger-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-default-500:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-500) / var(--heroui-default-500-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-default-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-primary:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-primary-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-foreground) / var(--heroui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-secondary:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-secondary-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-foreground) / var(--heroui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-success:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-success-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-warning:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-warning-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:shadow-danger\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--heroui-danger) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-default\/50:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--heroui-default) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-primary\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--heroui-primary) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-secondary\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--heroui-secondary) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-success\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--heroui-success) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-warning\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--heroui-warning) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:pointer-events-auto, .group[data-has-value="true"] .group-data-\[has-value\=true\]\:pointer-events-auto {
  pointer-events: auto;
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:start-0:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:start-0:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:z-10 {
  z-index: 10;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ms-4:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: 1rem;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ms-4:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ms-4:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ms-5:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: 1.25rem;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ms-5:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1.25rem;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ms-5:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1.25rem;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ms-6:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: 1.5rem;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ms-6:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1.5rem;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ms-6:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1.5rem;
}

.group[data-selected][data-pressed] .group-data-\[selected\]\:group-data-\[pressed\]\:ml-3 {
  margin-left: .75rem;
}

.group[data-selected][data-pressed] .group-data-\[selected\]\:group-data-\[pressed\]\:ml-4 {
  margin-left: 1rem;
}

.group[data-selected][data-pressed] .group-data-\[selected\]\:group-data-\[pressed\]\:ml-5 {
  margin-left: 1.25rem;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:block, .group[data-has-value="true"] .group-data-\[has-value\=true\]\:block {
  display: block;
}

.group[data-has-helper="true"] .group-data-\[has-helper\=true\]\:flex {
  display: flex;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:hidden {
  display: none;
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:w-5 {
  width: 1.25rem;
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:w-6 {
  width: 1.5rem;
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:w-7 {
  width: 1.75rem;
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.small\)\/2_\+_20px\)\] {
  --tw-translate-y: calc(calc(100% + var(--heroui-font-size-small) / 2 + 20px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.small\)\/2_\+_24px\)\] {
  --tw-translate-y: calc(calc(100% + var(--heroui-font-size-small) / 2 + 24px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.tiny\)\/2_\+_16px\)\] {
  --tw-translate-y: calc(calc(100% + var(--heroui-font-size-tiny) / 2 + 16px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_3\.5px\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-small) / 2 - 3.5px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_4px\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-small) / 2 - 4px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_6px\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-small) / 2 - 6px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_6px_-_theme\(borderWidth\.medium\)\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-small) / 2 - 6px - var(--heroui-border-width-medium)) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_8px\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-small) / 2 - 8px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_8px_-_theme\(borderWidth\.medium\)\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-small) / 2 - 8px - var(--heroui-border-width-medium)) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_5px\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-tiny) / 2 - 5px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_8px\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-tiny) / 2 - 8px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_8px_-_theme\(borderWidth\.medium\)\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-tiny) / 2 - 8px - var(--heroui-border-width-medium)) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.small\)\/2_\+_20px\)\] {
  --tw-translate-y: calc(calc(100% + var(--heroui-font-size-small) / 2 + 20px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.small\)\/2_\+_24px\)\] {
  --tw-translate-y: calc(calc(100% + var(--heroui-font-size-small) / 2 + 24px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.tiny\)\/2_\+_16px\)\] {
  --tw-translate-y: calc(calc(100% + var(--heroui-font-size-tiny) / 2 + 16px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_3\.5px\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-small) / 2 - 3.5px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_4px\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-small) / 2 - 4px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_6px\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-small) / 2 - 6px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_6px_-_theme\(borderWidth\.medium\)\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-small) / 2 - 6px - var(--heroui-border-width-medium)) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_8px\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-small) / 2 - 8px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_8px_-_theme\(borderWidth\.medium\)\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-small) / 2 - 8px - var(--heroui-border-width-medium)) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_5px\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-tiny) / 2 - 5px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_8px\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-tiny) / 2 - 8px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_8px_-_theme\(borderWidth\.medium\)\)\] {
  --tw-translate-y: calc(calc(50% + var(--heroui-font-size-tiny) / 2 - 8px - var(--heroui-border-width-medium)) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:translate-x-3 {
  --tw-translate-x: .75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-copied="true"] .group-data-\[copied\=true\]\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-copied="true"] .group-data-\[copied\=true\]\:scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:scale-85, .group[data-filled="true"] .group-data-\[filled\=true\]\:scale-85 {
  --tw-scale-x: .85;
  --tw-scale-y: .85;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-has-value="true"] .group-data-\[has-value\=true\]\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group\/tr[data-disabled="true"] .group-data-\[disabled\=true\]\/tr\:cursor-not-allowed {
  cursor: not-allowed;
}

.group[data-has-multiple-months="true"] .group-data-\[has-multiple-months\=true\]\:flex-row {
  flex-direction: row;
}

.group[data-has-label="true"] .group-data-\[has-label\=true\]\:items-start {
  align-items: flex-start;
}

.group[data-has-label="true"] .group-data-\[has-label\=true\]\:items-end {
  align-items: flex-end;
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:\!border-danger {
  --tw-border-opacity: 1 !important;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity))) !important;
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-danger {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-default-foreground {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:border-danger, .group[data-selected="true"] .group-data-\[selected\=true\]\:border-danger {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-default-500 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-500) / var(--heroui-default-500-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:\!bg-danger-50 {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity))) !important;
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-danger-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-default-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-default-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-200) / var(--heroui-default-200-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-primary-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-50) / var(--heroui-primary-50-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-secondary-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-50) / var(--heroui-secondary-50-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-success-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-50) / var(--heroui-success-50-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-warning-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-50) / var(--heroui-warning-50-opacity, var(--tw-bg-opacity)));
}

.group[data-hover-unselected="true"] .group-data-\[hover-unselected\=true\]\:bg-default-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-bg-opacity)));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:bg-danger-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-danger {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-default-400 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-400) / var(--heroui-default-400-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-secondary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.group[data-has-helper="true"] .group-data-\[has-helper\=true\]\:pt-2 {
  padding-top: .5rem;
}

.group[data-has-helper="true"] .group-data-\[has-helper\=true\]\:pt-3 {
  padding-top: .75rem;
}

.group[data-has-helper="true"] .group-data-\[has-helper\=true\]\:pt-4, .group[data-has-label="true"] .group-data-\[has-label\=true\]\:pt-4 {
  padding-top: 1rem;
}

.group[data-has-label="true"] .group-data-\[has-label\=true\]\:pt-5 {
  padding-top: 1.25rem;
}

.group\/tr[data-disabled="true"] .group-data-\[disabled\=true\]\/tr\:text-foreground-300 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground-300) / var(--heroui-foreground-300-opacity, var(--tw-text-opacity)));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:text-default-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-600) / var(--heroui-default-600-opacity, var(--tw-text-opacity)));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:text-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:text-default-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-600) / var(--heroui-default-600-opacity, var(--tw-text-opacity)));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:text-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-has-value="true"] .group-data-\[has-value\=true\]\:text-default-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-has-value="true"] .group-data-\[has-value\=true\]\:text-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:text-danger, .group[data-selected="true"] .group-data-\[selected\=true\]\:text-danger {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-danger-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-default-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-default-foreground) / var(--heroui-default-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-foreground) / var(--heroui-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary) / var(--heroui-primary-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-primary-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-primary-foreground) / var(--heroui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary) / var(--heroui-secondary-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-secondary-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-secondary-foreground) / var(--heroui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-success {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-success-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-warning-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-copied="true"] .group-data-\[copied\=true\]\:opacity-0 {
  opacity: 0;
}

.group[data-copied="true"] .group-data-\[copied\=true\]\:opacity-100 {
  opacity: 1;
}

.group[data-has-value="true"] .group-data-\[has-value\=true\]\:opacity-70 {
  opacity: .7;
}

.group\/th[data-hover="true"] .group-data-\[hover\=true\]\/th\:opacity-100, .group[data-loaded="true"] .group-data-\[loaded\=true\]\:opacity-100 {
  opacity: 1;
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:opacity-70 {
  opacity: .7;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:opacity-0 {
  opacity: 0;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:opacity-100 {
  opacity: 1;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:opacity-60 {
  opacity: .6;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:ring-focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--heroui-focus) / var(--heroui-focus-opacity, var(--tw-ring-opacity)));
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:ring-offset-background {
  --tw-ring-offset-color: hsl(var(--heroui-background) / var(--heroui-background-opacity, 1));
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:before\:-z-10:before {
  content: var(--tw-content);
  z-index: -10;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.group[data-open="true"] .group-data-\[open\=true\]\:before\:translate-y-px:before {
  content: var(--tw-content);
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-open="true"] .group-data-\[open\=true\]\:before\:rotate-45:before {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group\/tr[data-middle="true"] .group-data-\[middle\=true\]\/tr\:before\:rounded-none:before {
  content: var(--tw-content);
  border-radius: 0;
}

.group[data-hover="true"] .group-data-\[hover\=true\]\:before\:bg-default-100:before, .group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:before\:bg-default-100:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-bg-opacity)));
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:before\:opacity-100:before {
  content: var(--tw-content);
  opacity: 1;
}

.group[data-required="true"] .group-data-\[required\=true\]\:after\:ml-0\.5:after {
  content: var(--tw-content);
  margin-left: .125rem;
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.group[data-open="true"] .group-data-\[open\=true\]\:after\:translate-y-0:after {
  content: var(--tw-content);
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-open="true"] .group-data-\[open\=true\]\:after\:-rotate-45:after {
  content: var(--tw-content);
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:after\:scale-100:after {
  content: var(--tw-content);
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:after\:bg-danger:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.group[data-required="true"] .group-data-\[required\=true\]\:after\:text-danger:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:after\:opacity-100:after {
  content: var(--tw-content);
  opacity: 1;
}

.group[data-required="true"] .group-data-\[required\=true\]\:after\:content-\[\'\*\'\]:after {
  --tw-content: "*";
  content: var(--tw-content);
}

.group\/tr[data-first="true"] .group-data-\[first\=true\]\/tr\:first\:before\:rounded-ss-lg:first-child:before {
  content: var(--tw-content);
}

.group\/tr[data-first="true"] .group-data-\[first\=true\]\/tr\:first\:before\:rounded-ss-lg:first-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: .5rem;
}

.group\/tr[data-first="true"] .group-data-\[first\=true\]\/tr\:first\:before\:rounded-ss-lg:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: .5rem;
}

.group\/tr[data-first="true"] .group-data-\[first\=true\]\/tr\:first\:before\:rounded-ss-lg:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: .5rem;
}

.group\/tr[data-last="true"] .group-data-\[last\=true\]\/tr\:first\:before\:rounded-es-lg:first-child:before {
  content: var(--tw-content);
}

.group\/tr[data-last="true"] .group-data-\[last\=true\]\/tr\:first\:before\:rounded-es-lg:first-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-bottom-left-radius: .5rem;
}

.group\/tr[data-last="true"] .group-data-\[last\=true\]\/tr\:first\:before\:rounded-es-lg:first-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-bottom-right-radius: .5rem;
}

.group\/tr[data-last="true"] .group-data-\[last\=true\]\/tr\:first\:before\:rounded-es-lg:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-bottom-right-radius: .5rem;
}

.group\/tr[data-first="true"] .group-data-\[first\=true\]\/tr\:last\:before\:rounded-se-lg:last-child:before {
  content: var(--tw-content);
}

.group\/tr[data-first="true"] .group-data-\[first\=true\]\/tr\:last\:before\:rounded-se-lg:last-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: .5rem;
}

.group\/tr[data-first="true"] .group-data-\[first\=true\]\/tr\:last\:before\:rounded-se-lg:last-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: .5rem;
}

.group\/tr[data-first="true"] .group-data-\[first\=true\]\/tr\:last\:before\:rounded-se-lg:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: .5rem;
}

.group\/tr[data-last="true"] .group-data-\[last\=true\]\/tr\:last\:before\:rounded-ee-lg:last-child:before {
  content: var(--tw-content);
}

.group\/tr[data-last="true"] .group-data-\[last\=true\]\/tr\:last\:before\:rounded-ee-lg:last-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-bottom-right-radius: .5rem;
}

.group\/tr[data-last="true"] .group-data-\[last\=true\]\/tr\:last\:before\:rounded-ee-lg:last-child:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-bottom-left-radius: .5rem;
}

.group\/tr[data-last="true"] .group-data-\[last\=true\]\/tr\:last\:before\:rounded-ee-lg:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-bottom-left-radius: .5rem;
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:hover\:border-danger:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:hover\:bg-danger-100:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-100) / var(--heroui-danger-100-opacity, var(--tw-bg-opacity)));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:focus-within\:hover\:border-danger:hover:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-border-opacity)));
}

.group[data-invalid="true"] .group-data-\[invalid\=true\]\:focus-within\:hover\:bg-danger-50:hover:focus-within {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity)));
}

.group\/tr[aria-selected="false"][data-hover="true"] .group-aria-\[selected\=false\]\/tr\:group-data-\[hover\=true\]\/tr\:before\:bg-default-100:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-bg-opacity)));
}

.group\/tr[aria-selected="false"][data-hover="true"] .group-aria-\[selected\=false\]\/tr\:group-data-\[hover\=true\]\/tr\:before\:opacity-70:before {
  content: var(--tw-content);
  opacity: .7;
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:data-\[selected\=true\]\/tr\:before\:bg-danger\/20[data-selected="true"]:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-danger) / .2);
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:data-\[selected\=true\]\/tr\:before\:bg-default\/60[data-selected="true"]:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-default) / .6);
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:data-\[selected\=true\]\/tr\:before\:bg-primary\/20[data-selected="true"]:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-primary) / .2);
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:data-\[selected\=true\]\/tr\:before\:bg-secondary\/20[data-selected="true"]:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-secondary) / .2);
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:data-\[selected\=true\]\/tr\:before\:bg-success\/20[data-selected="true"]:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-success) / .2);
}

.group\/tr[data-odd="true"] .group-data-\[odd\=true\]\/tr\:data-\[selected\=true\]\/tr\:before\:bg-warning\/20[data-selected="true"]:before {
  content: var(--tw-content);
  background-color: hsl(var(--heroui-warning) / .2);
}

.peer[data-filled="true"] ~ .peer-data-\[filled\=true\]\:pointer-events-auto {
  pointer-events: auto;
}

.peer[data-filled="true"] ~ .peer-data-\[filled\=true\]\:block {
  display: block;
}

.peer[data-filled="true"] ~ .peer-data-\[filled\=true\]\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer[data-filled="true"] ~ .peer-data-\[filled\=true\]\:opacity-70 {
  opacity: .7;
}

@media (prefers-reduced-motion: reduce) {
  .motion-reduce\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .motion-reduce\:transition-none {
    transition-property: none;
  }

  .motion-reduce\:after\:transition-none:after {
    content: var(--tw-content);
    transition-property: none;
  }
}

.dark\:border-danger-100:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-danger-100) / var(--heroui-danger-100-opacity, var(--tw-border-opacity)));
}

.dark\:border-default-200:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-default-200) / var(--heroui-default-200-opacity, var(--tw-border-opacity)));
}

.dark\:border-primary-100:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-primary-100) / var(--heroui-primary-100-opacity, var(--tw-border-opacity)));
}

.dark\:border-success-100:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-success-100) / var(--heroui-success-100-opacity, var(--tw-border-opacity)));
}

.dark\:border-warning-100:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: hsl(var(--heroui-warning-100) / var(--heroui-warning-100-opacity, var(--tw-border-opacity)));
}

.dark\:bg-background:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-background) / var(--heroui-background-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-background\/20:is(.dark *) {
  background-color: hsl(var(--heroui-background) / .2);
}

.dark\:bg-content2:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-content2) / var(--heroui-content2-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-danger-100:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-100) / var(--heroui-danger-100-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-danger-50:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-danger-50\/50:is(.dark *) {
  background-color: hsl(var(--heroui-danger-50) / .5);
}

.dark\:bg-default:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default) / var(--heroui-default-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-default-100:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-default-50\/50:is(.dark *) {
  background-color: hsl(var(--heroui-default-50) / .5);
}

.dark\:bg-primary-100:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-100) / var(--heroui-primary-100-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-primary-50:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-primary-50) / var(--heroui-primary-50-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-primary-50\/50:is(.dark *) {
  background-color: hsl(var(--heroui-primary-50) / .5);
}

.dark\:bg-secondary-100:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-100) / var(--heroui-secondary-100-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-secondary-50:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-secondary-50) / var(--heroui-secondary-50-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-secondary-50\/50:is(.dark *) {
  background-color: hsl(var(--heroui-secondary-50) / .5);
}

.dark\:bg-success-100:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-100) / var(--heroui-success-100-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-success-50:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-50) / var(--heroui-success-50-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-success-50\/50:is(.dark *) {
  background-color: hsl(var(--heroui-success-50) / .5);
}

.dark\:bg-transparent:is(.dark *) {
  background-color: #0000;
}

.dark\:bg-warning-100:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-100) / var(--heroui-warning-100-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-warning-50:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-50) / var(--heroui-warning-50-opacity, var(--tw-bg-opacity)));
}

.dark\:bg-warning-50\/50:is(.dark *) {
  background-color: hsl(var(--heroui-warning-50) / .5);
}

.dark\:text-danger-500:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-500) / var(--heroui-danger-500-opacity, var(--tw-text-opacity)));
}

.dark\:text-success:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-text-opacity)));
}

.dark\:text-warning:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-text-opacity)));
}

.dark\:placeholder\:text-danger-500:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-500) / var(--heroui-danger-500-opacity, var(--tw-text-opacity)));
}

.dark\:placeholder\:text-success:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-text-opacity)));
}

.dark\:placeholder\:text-warning:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-text-opacity)));
}

.dark\:before\:via-default-700\/10:is(.dark *):before {
  content: var(--tw-content);
  --tw-gradient-to: hsl(var(--heroui-default-700) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--heroui-default-700) / .1) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.dark\:after\:bg-content2:is(.dark *):after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-content2) / var(--heroui-content2-opacity, var(--tw-bg-opacity)));
}

.dark\:focus\:bg-danger-400\/20:focus:is(.dark *) {
  background-color: hsl(var(--heroui-danger-400) / .2);
}

.dark\:focus\:bg-success-400\/20:focus:is(.dark *) {
  background-color: hsl(var(--heroui-success-400) / .2);
}

.dark\:focus\:bg-warning-400\/20:focus:is(.dark *) {
  background-color: hsl(var(--heroui-warning-400) / .2);
}

.dark\:data-\[hover\=true\]\:bg-content2[data-hover="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-content2) / var(--heroui-content2-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[hover\=true\]\:bg-danger-50[data-hover="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger-50) / var(--heroui-danger-50-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[hover\=true\]\:bg-success-50[data-hover="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-50) / var(--heroui-success-50-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[hover\=true\]\:bg-warning-50[data-hover="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-50) / var(--heroui-warning-50-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[hover\=true\]\:bg-danger[data-hover="true"][data-selected="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-danger) / var(--heroui-danger-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[hover\=true\]\:bg-success[data-hover="true"][data-selected="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[hover\=true\]\:bg-warning[data-hover="true"][data-selected="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[hover\=true\]\:text-danger-500[data-hover="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-500) / var(--heroui-danger-500-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[hover\=true\]\:text-success-500[data-hover="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-500) / var(--heroui-success-500-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[hover\=true\]\:text-warning-500[data-hover="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-500) / var(--heroui-warning-500-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[hover\=true\]\:text-danger-foreground[data-hover="true"][data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-foreground) / var(--heroui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[hover\=true\]\:text-success-foreground[data-hover="true"][data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[hover\=true\]\:text-warning-foreground[data-hover="true"][data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning-foreground) / var(--heroui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[range-selection\=true\]\:text-success-500[data-range-selection="true"][data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-500) / var(--heroui-success-500-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[selection-end\=true\]\:data-\[range-selection\=true\]\:text-success-foreground[data-range-selection="true"][data-selection-end="true"][data-selected="true"]:is(.dark *), .dark\:data-\[selected\=true\]\:data-\[selection-start\=true\]\:data-\[range-selection\=true\]\:text-success-foreground[data-range-selection="true"][data-selection-start="true"][data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success-foreground) / var(--heroui-success-foreground-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:text-danger-500[data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-danger-500) / var(--heroui-danger-500-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:text-success[data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-success) / var(--heroui-success-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:text-warning[data-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: hsl(var(--heroui-warning) / var(--heroui-warning-opacity, var(--tw-text-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-success-50[data-range-selection="true"][data-selected="true"]:is(.dark *):before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-success-50) / var(--heroui-success-50-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[selected\=true\]\:data-\[range-selection\=true\]\:before\:bg-warning-50[data-range-selection="true"][data-selected="true"]:is(.dark *):before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-warning-50) / var(--heroui-warning-50-opacity, var(--tw-bg-opacity)));
}

.dark\:data-\[invalid\=true\]\:focus\:bg-danger-400\/20:focus[data-invalid="true"]:is(.dark *) {
  background-color: hsl(var(--heroui-danger-400) / .2);
}

@media (width >= 640px) {
  .sm\:m-0 {
    margin: 0;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:w-\[356px\] {
    width: 356px;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:\[--scale-enter\:100\%\] {
    --scale-enter: 100%;
  }

  .sm\:\[--scale-exit\:103\%\] {
    --scale-exit: 103%;
  }

  .sm\:\[--slide-enter\:0px\] {
    --slide-enter: 0px;
  }

  .sm\:\[--slide-exit\:0px\] {
    --slide-exit: 0px;
  }

  .sm\:data-\[visible\=true\]\:pointer-events-none[data-visible="true"] {
    pointer-events: none;
  }

  .sm\:data-\[placement\=bottom-center\]\:mx-auto[data-placement="bottom-center"], .sm\:data-\[placement\=top-center\]\:mx-auto[data-placement="top-center"] {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:data-\[placement\=bottom-left\]\:ml-2[data-placement="bottom-left"] {
    margin-left: .5rem;
  }

  .sm\:data-\[placement\=bottom-right\]\:mr-2[data-placement="bottom-right"] {
    margin-right: .5rem;
  }

  .sm\:data-\[placement\=top-left\]\:ml-2[data-placement="top-left"] {
    margin-left: .5rem;
  }

  .sm\:data-\[placement\=top-right\]\:mr-2[data-placement="top-right"] {
    margin-right: .5rem;
  }

  .sm\:data-\[placement\=bottom-center\]\:w-max[data-placement="bottom-center"], .sm\:data-\[placement\=bottom-left\]\:w-max[data-placement="bottom-left"], .sm\:data-\[placement\=bottom-right\]\:w-max[data-placement="bottom-right"], .sm\:data-\[placement\=top-center\]\:w-max[data-placement="top-center"], .sm\:data-\[placement\=top-left\]\:w-max[data-placement="top-left"], .sm\:data-\[placement\=top-right\]\:w-max[data-placement="top-right"] {
    width: max-content;
  }

  .sm\:data-\[visible\=true\]\:opacity-0[data-visible="true"] {
    opacity: 0;
  }

  .group[data-hover="true"] .sm\:group-data-\[hover\=true\]\:data-\[visible\=true\]\:pointer-events-auto[data-visible="true"] {
    pointer-events: auto;
  }

  .group[data-hover="true"] .sm\:group-data-\[hover\=true\]\:data-\[visible\=true\]\:opacity-100[data-visible="true"] {
    opacity: 1;
  }
}

.rtl\:left-2:where([dir="rtl"], [dir="rtl"] *) {
  left: .5rem;
}

.rtl\:right-auto:where([dir="rtl"], [dir="rtl"] *) {
  right: auto;
}

.rtl\:origin-top-right:where([dir="rtl"], [dir="rtl"] *) {
  transform-origin: 100% 0;
}

.rtl\:-rotate-180:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rtl\:rotate-180:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rtl\:flex-row-reverse:where([dir="rtl"], [dir="rtl"] *) {
  flex-direction: row-reverse;
}

.rtl\:space-x-reverse:where([dir="rtl"], [dir="rtl"] *) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.rtl\:data-\[focus-visible\=true\]\:translate-x-3[data-focus-visible="true"]:where([dir="rtl"], [dir="rtl"] *), .rtl\:data-\[hover\=true\]\:translate-x-3[data-hover="true"]:where([dir="rtl"], [dir="rtl"] *) {
  --tw-translate-x: .75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rtl\:data-\[open\=true\]\:-rotate-90[data-open="true"]:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\+\.border-medium\.border-danger\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-danger:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-danger\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-danger:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-danger\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-danger:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-default\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-default:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-default\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-default:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-default\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-default:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-primary\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-primary:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-primary\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-primary:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-primary\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-primary:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-secondary\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-secondary:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-secondary\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-secondary:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-secondary\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-secondary:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-success\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-success:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-success\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-success:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-success\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-success:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-warning\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-warning:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-warning\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-warning:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\+\.border-medium\.border-warning\]\:ms-\[calc\(theme\(borderWidth\.medium\)\*-1\)\] + .border-medium.border-warning:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: calc(var(--heroui-border-width-medium) * -1);
}

.\[\&\:not\(\:first-child\)\:not\(\:last-child\)\]\:rounded-none:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.\[\&\:not\(\:first-child\)\]\:-ml-1:not(:first-child) {
  margin-left: -.25rem;
}

.\[\&\:not\(\:first-of-type\)\:not\(\:last-of-type\)\]\:rounded-none:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.\[\&\:not\(\:first-of-type\)\]\:ms-\[calc\(theme\(borderWidth\.2\)\*-1\)\]:not(:first-of-type):not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: -2px;
}

.\[\&\:not\(\:first-of-type\)\]\:ms-\[calc\(theme\(borderWidth\.2\)\*-1\)\]:not(:first-of-type):-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: -2px;
}

.\[\&\:not\(\:first-of-type\)\]\:ms-\[calc\(theme\(borderWidth\.2\)\*-1\)\]:not(:first-of-type):is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: -2px;
}

.\[\&\>\*\]\:relative > * {
  position: relative;
}

.\[\&\>svg\]\:max-w-\[theme\(spacing\.8\)\] > svg {
  max-width: 2rem;
}

.\[\&\>tr\]\:first\:rounded-lg:first-child > tr {
  border-radius: .5rem;
}

.\[\&\>tr\]\:first\:shadow-small:first-child > tr {
  --tw-shadow: var(--heroui-box-shadow-small);
  --tw-shadow-colored: var(--heroui-box-shadow-small);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[\&\[data-hover\=true\]\:not\(\[data-active\=true\]\)\]\:bg-default-100[data-hover="true"]:not([data-active="true"]) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-100) / var(--heroui-default-100-opacity, var(--tw-bg-opacity)));
}

.\[\&\[data-hover\=true\]\:not\(\[data-active\=true\]\)\]\:bg-default-200[data-hover="true"]:not([data-active="true"]) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--heroui-default-200) / var(--heroui-default-200-opacity, var(--tw-bg-opacity)));
}

.\[\&_\.chevron-icon\]\:flex-none .chevron-icon {
  flex: none;
}

.\[\&_\.chevron-icon\]\:rotate-180 .chevron-icon {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&_\.chevron-icon\]\:transition-transform .chevron-icon {
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

[data-rsbs-overlay] {
  border-top-left-radius: 16px;
  border-top-left-radius: var(--rsbs-overlay-rounded, 16px);
  border-top-right-radius: 16px;
  border-top-right-radius: var(--rsbs-overlay-rounded, 16px);
  background: #fff;
  background: var(--rsbs-bg, #fff);
  height: 0;
  height: var(--rsbs-overlay-h, 0px);
  transform: translate3d(0, 0, 0);
  transform: translate3d(0, var(--rsbs-overlay-translate-y, 0px), 0);
  will-change: height;
  flex-direction: column;
  display: flex;
}

[data-rsbs-overlay]:focus {
  outline: none;
}

[data-rsbs-is-blocking="false"] [data-rsbs-overlay] {
  box-shadow: 0 -5px 60px #2659731c, 0 -1px #2659730d;
}

[data-rsbs-overlay], [data-rsbs-root]:after {
  max-width: auto;
  max-width: var(--rsbs-max-w, auto);
  margin-left: env(safe-area-inset-left);
  margin-left: var(--rsbs-ml, env(safe-area-inset-left));
  margin-right: env(safe-area-inset-right);
  margin-right: var(--rsbs-mr, env(safe-area-inset-right));
}

[data-rsbs-overlay], [data-rsbs-backdrop], [data-rsbs-root]:after {
  z-index: 3;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

[data-rsbs-backdrop] {
  background-color: #0009;
  background-color: var(--rsbs-backdrop-bg, #0009);
  will-change: opacity;
  cursor: pointer;
  opacity: 1;
  top: -60px;
  bottom: -60px;
}

[data-rsbs-is-dismissable="false"] [data-rsbs-backdrop] {
  cursor: ns-resize;
}

[data-rsbs-root]:after {
  content: "";
  pointer-events: none;
  background: #fff;
  background: var(--rsbs-bg, #fff);
  transform-origin: bottom;
  height: 1px;
  transform: scale3d(1, 0, 1);
  transform: scale3d(1, var(--rsbs-antigap-scale-y, 0), 1);
  will-change: transform;
}

[data-rsbs-footer], [data-rsbs-header] {
  cursor: ns-resize;
  flex-shrink: 0;
  padding: 16px;
}

[data-rsbs-header] {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  box-shadow: 0 1px #2e3b4220;
  box-shadow: 0 1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity, 1) * .125));
  z-index: 1;
  padding-top: calc(20px + env(safe-area-inset-top));
  padding-bottom: 8px;
}

[data-rsbs-header]:before {
  content: "";
  width: 36px;
  height: 4px;
  top: calc(8px + env(safe-area-inset-top));
  background-color: #00000024;
  background-color: var(--rsbs-handle-bg, #00000024);
  border-radius: 2px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media (-webkit-device-pixel-ratio >= 2), (resolution >= 2x) {
  [data-rsbs-header]:before {
    transform: translateX(-50%)scaleY(.75);
  }
}

[data-rsbs-has-header="false"] [data-rsbs-header] {
  box-shadow: none;
  padding-top: calc(12px + env(safe-area-inset-top));
}

[data-rsbs-scroll] {
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  user-select: auto;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
}

[data-rsbs-scroll]:focus {
  outline: none;
}

[data-rsbs-has-footer="false"] [data-rsbs-content] {
  padding-bottom: env(safe-area-inset-bottom);
}

[data-rsbs-content] {
  overflow: hidden;
}

[data-rsbs-footer] {
  box-shadow: 0 -1px #2e3b4220, 0 2px #fff;
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity, 1) * .125)), 0 2px 0 var(--rsbs-bg, #fff);
  z-index: 1;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
  overflow: hidden;
}

[data-rsbs-is-dismissable="true"] [data-rsbs-header] > *, [data-rsbs-is-dismissable="true"] [data-rsbs-scroll] > *, [data-rsbs-is-dismissable="true"] [data-rsbs-footer] > *, [data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-header] > *, [data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-header] > *, [data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-scroll] > *, [data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-scroll] > *, [data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-footer] > *, [data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-footer] > * {
  opacity: 1;
  opacity: var(--rsbs-content-opacity, 1);
}

[data-rsbs-is-dismissable="true"] [data-rsbs-backdrop], [data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-backdrop], [data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-backdrop] {
  opacity: 1;
  opacity: var(--rsbs-backdrop-opacity, 1);
}

[data-rsbs-state="closed"], [data-rsbs-state="closing"] {
  pointer-events: none;
}

body {
  margin: 0;
  font-family: DiatypeRounded, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
}

::-webkit-scrollbar {
  background: none;
  width: 0;
}

body > div > div > main {
  margin: 0 !important;
}

.mobile-messages-container {
  height: calc(100% - 254px);
  margin-top: 10px;
}

.test-toast {
  top: 10px !important;
}

.sitemap {
  color: inherit;
  font-size: 15px;
  position: absolute;
  bottom: 8px;
}

@media only screen and (width <= 945px) {
  .mobile-messages-container {
    height: calc(100% - 240px);
  }

  .mobile-messages-container-full {
    height: calc(100% - 60px);
  }

  .question-search-container, .users-search-container {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
  }

  .search-input-container {
    width: calc(100dvw - 20px) !important;
  }

  .test-toast > div {
    justify-content: center !important;
  }
}

@media only screen and (width <= 1040px) {
  body > div > div > main {
    gap: 32px !important;
  }
}

@media only screen and (width <= 958px) {
  body > div > div > main {
    gap: 24px !important;
  }
}

.test-toast > div > div {
  box-sizing: border-box;
  border-radius: 24px;
  min-width: 310px;
  max-width: 340px;
  color: #fff !important;
  background: #333 !important;
}

.test-toast > div > div > div {
  margin: 4px 0 !important;
}

p {
  margin: 0;
}

.reset-filters-button {
  cursor: pointer;
  color: #3b3b3b;
  background: #2b2b2b0f;
  border: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  padding: 9px 16px;
  font-size: 16px;
  line-height: 1;
  transition: color .1s;
  display: flex;
  position: relative;
  top: 0;
  right: 0;
  transform: none;
}

.landing-button {
  cursor: pointer;
  color: #3b3b3b;
  text-align: center;
  background: none;
  border: none;
  width: 100%;
  position: absolute;
  bottom: 8px;
}

@media only screen and (width <= 606px) {
  .test-toast > div > div {
    box-sizing: border-box;
    color: #2b2b2b;
    width: calc(100% - 20px);
    max-width: unset;
    background: #e6e3e1;
  }

  .test-toast {
    left: 0 !important;
    right: 0 !important;
  }
}

.connect-wallet-toast-button {
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-size: 1em;
  font-weight: bold;
  text-decoration: underline;
}

.icon {
  color: #fff;
  z-index: 101;
  background: #06f;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

@supports ((-webkit-backdrop-filter: blur()) or (backdrop-filter: blur())) {
  .search-modal {
    backdrop-filter: blur(6px);
    background: #f4f3fd3b !important;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

.open-app-button {
  letter-spacing: -.5px;
  box-sizing: border-box;
  -webkit-text-decoration: unset;
  text-decoration: unset;
  color: #f1f1f1;
  cursor: pointer;
  background: #3eb5f1;
  border: none;
  border-radius: 29px;
  outline: none;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  padding: 12px 18px;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  transition: box-shadow .3s ease-out;
  display: flex;
  box-shadow: 0 0 23.84px 8.16px #3eb5f163;
}

.open-app-button:hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}

.tabs-container {
  background: #f5f5f7;
  border-radius: 12px;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: 10px auto auto;
  padding: 5px;
  display: flex;
}

.tabs-header {
  background: #f5f5f7;
  border-radius: 12px;
  gap: 5px;
  padding: 5px;
  display: flex;
}

.tab-button {
  cursor: pointer;
  color: #777;
  background: none;
  border: none;
  border-radius: 10px;
  flex: 1;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  transition: all .3s;
}

.tab-button.active {
  color: #000;
  background: #fff;
  font-weight: bold;
  box-shadow: 0 2px 5px #0000001a;
}

.tabs-content {
  color: #444;
  background: #fff;
  border-radius: 12px;
  margin-top: 10px;
  padding: 15px;
  font-size: 14px;
  box-shadow: 0 2px 5px #0000001a;
}

svg:focus {
  outline: none !important;
}

.bottom-bar {
  box-sizing: border-box;
  width: calc(100dvw - 20px);
  height: 141px;
  transition: transform .3s;
  display: flex;
  position: fixed;
  left: 10px;
  right: 0;
}

.bottom-bar.hidden {
  transform: translateY(100%);
}

.bottom-bar.visible {
  transform: translateY(0);
}

.user-progress-bar {
  align-items: center;
  display: flex;
}
/*# sourceMappingURL=public.59e4ce2c.css.map */
